//FAQ.js
import React, { useState } from 'react';
import './FAQ.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Tooltip from '@mui/material/Tooltip';

const FAQ = () => {
  const [expanded, setExpanded] = useState({});

  const toggleExpand = (index) => {
    setExpanded(prev => ({ ...prev, [index]: !prev[index] }));
  };

  const faqs = [
    { question: 'What do I do if data is not syncing correctly?', answer: 'When data is not syncing, or if you are getting errors, try logging out/logging back in. There is a chance that your data synced correctly but took too long to update the frontend.' },
    { question: 'What does a blue border mean?', answer: 'When an app has a blue border, that means the data for that app is connected and ready to be integrated into other apps.' },
    { question: 'What does a dashed border mean?', answer: 'When an app has a dashed border, that means the app is ready to receive data; you can drag/drop another app into the app with a dashed border.' }
  ];

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleExpand(index)}>
            <div>{faq.question}</div>
            <Tooltip title={expanded[index] ? "Close" : "Open"}>
              {expanded[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Tooltip>
          </div>
          {expanded[index] && <div className="faq-answer">{faq.answer}</div>}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
