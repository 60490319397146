//RequestDemoForm.js
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box, Typography, CircularProgress, Snackbar, Alert, IconButton, Divider, Tooltip, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useAnalytics from './hooks/UseAnalytics';
import { motion, AnimatePresence } from 'framer-motion';
import PricingSlider from './PricingSlider';
import { usePricing } from './PricingContext';
import { getClientInfo, getSource, getSessionInfo } from './hooks/ClientInfo';
import PhoneInput from './PhoneInput';

const RequestDemoForm = ({ open, onClose, plan }) => {
  const theme = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { trackEvent, sessionId } = useAnalytics();
  const { crmSize, billingCycle } = usePricing();

  useEffect(() => {
    if (open) {
      trackEvent('demo_form_opened', { plan, crmSize, billingCycle });
    }
  }, [open, crmSize, plan, billingCycle, trackEvent]);

  const phoneRegExp = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      phoneNumber: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      companyName: Yup.string().required('Company name is required'),
      phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        const clientInfo = getClientInfo();
        const sourceInfo = getSource();
        const sessionInfo = getSessionInfo();

        const demoRequestData = {
          ...values,
          plan,
          crmSize,
          selectedBillingCycle: billingCycle,
          sessionId,
          clientInfo,
          sourceInfo,
          sessionInfo,
        };

        const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/demo-request`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(demoRequestData),
        });

        if (!response.ok) throw new Error('Failed to process demo request');

        const result = await response.json();

        setShowSuccessMessage(true);
        formik.resetForm();
        trackEvent('demo_request_processed', { ...demoRequestData, id: result.id });
        setTimeout(() => {
          onClose();
          setShowSuccessMessage(false);
        }, 3000);
      } catch (error) {
        console.error('Error processing demo request:', error);
        trackEvent('demo_request_error', { error: error.message });
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const inputFields = [
    { name: 'firstName', label: 'First Name', icon: <PersonOutlineIcon />, placeholder: 'Enter your first name' },
    { name: 'lastName', label: 'Last Name', icon: <PersonOutlineIcon />, placeholder: 'Enter your last name' },
    { name: 'email', label: 'Email', icon: <EmailOutlinedIcon />, placeholder: 'Enter your email address' },
    { name: 'companyName', label: 'Company Name', icon: <BusinessOutlinedIcon />, placeholder: 'Enter your company name' },
  ];

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      fullWidth 
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: 3,
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
          overflow: 'hidden',
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          pb: 1, 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          background: theme.palette.primary.main,
          color: 'white',
        }}
      >
        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
          Get a Free Personalized Demo
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 4 }}>
        <Box sx={{ 
          my: 2, 
          p: 3, 
          borderRadius: 2, 
          backgroundColor: theme.palette.grey[50],
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
        }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
            Automate maintenance of your {crmSize.toLocaleString()} contacts
          </Typography>
          <PricingSlider context="requestDemo" />
          <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            Custom pricing for {crmSize.toLocaleString()} contacts
            <Tooltip title="Official quote will be provided during your demo" arrow>
              <IconButton size="small" sx={{ ml: 1 }}>
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic', mt: 1 }}>
            No credit card required
          </Typography>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Box component="form" onSubmit={formik.handleSubmit} noValidate>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
            Your Information
          </Typography>
          <AnimatePresence>
            {inputFields.map((field) => (
              <motion.div
                key={field.name}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  id={field.name}
                  label={field.label}
                  name={field.name}
                  autoComplete={field.name}
                  value={formik.values[field.name]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                  helperText={formik.touched[field.name] && formik.errors[field.name]}
                  placeholder={field.placeholder}
                  InputProps={{
                    endAdornment: (
                      <Box 
                        component="span" 
                        sx={{ 
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '48px',
                          height: '48px',
                          color: 'action.active',
                          position: 'absolute',
                          right: '4px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                        }}
                      >
                        {field.icon}
                      </Box>
                    ),
                    sx: {
                      height: '56px',
                      paddingRight: '52px',
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      '&:hover fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: theme.palette.primary.main,
                        borderWidth: '2px',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      '&.Mui-focused': {
                        color: theme.palette.primary.main,
                      },
                    },
                    '& .MuiOutlinedInput-input': {
                      padding: '16px 14px',
                    },
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    },
                    mb: 2,
                    width: '100%',
                  }}
                />
              </motion.div>
            ))}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <PhoneInput
                margin="dense"
                required
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                autoComplete="tel"
                value={formik.values.phoneNumber}
                onChange={(value) => formik.setFieldValue('phoneNumber', value)}
                onBlur={formik.handleBlur}
                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                placeholder="Enter your phone number"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    '&:hover fieldset': {
                      borderColor: theme.palette.primary.main,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: theme.palette.primary.main,
                      borderWidth: '2px',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    '&.Mui-focused': {
                      color: theme.palette.primary.main,
                    },
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: '16px 14px',
                  },
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                  },
                  mb: 2,
                  width: '100%',
                }}
              />
            </motion.div>
          </AnimatePresence>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 4, py: 3, justifyContent: 'space-between', background: theme.palette.grey[50] }}>
        <Button 
          onClick={onClose} 
          color="primary"
          sx={{
            borderRadius: '8px',
            padding: '10px 16px',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={formik.handleSubmit}
          variant="contained"
          disabled={isSubmitting}
          sx={{
            minWidth: 180,
            borderRadius: '8px',
            padding: '12px 24px',
            fontWeight: 'bold',
            textTransform: 'none',
            transition: 'all 0.3s ease',
            backgroundColor: 'var(--cta-color)', // Changed to use var(--cta-color)
            color: 'white',
            '&:hover': {
              backgroundColor: 'var(--cta-color)', // Keep the same color on hover
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
            },
          }}
        >
          {isSubmitting ? <CircularProgress size={24} /> : 'Request Demo'}
        </Button>
      </DialogActions>
      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={5000}
        onClose={() => setShowSuccessMessage(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setShowSuccessMessage(false)} 
          severity="success" 
          sx={{ width: '100%' }}
          elevation={6}
          variant="filled"
        >
          Demo request processed successfully! We'll be in touch soon.
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default RequestDemoForm;