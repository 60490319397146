//CompanyProfileTable.js

import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Card, CardContent, Typography, Avatar, CircularProgress, Chip } from '@mui/material';
import './ProfileTable.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UpdateIcon from '@mui/icons-material/Update';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useCodeBuddyData } from './CodeBuddyDataContext'; // Adjust the import path as necessary
import CompanySearchBar from './CompanySearchBar'; // Adjust the import path as necessary


const stateIcons = {
  Active: <CheckCircleOutlineIcon style={{ color: 'green' }} />,
  Updated: <UpdateIcon style={{ color: 'orange' }} />,
  Unsubscribed: <CancelIcon style={{ color: 'red' }} />,
  Deleted: <DeleteIcon style={{ color: 'gray' }} />,
  Bounced: <ErrorOutlineIcon style={{ color: 'purple' }} />,
  Null: <HelpOutlineIcon style={{ color: 'lightgray' }} />
};


const displayEnrichedOriginalOrPlaceholderValue = (enrichedValue, originalValue, placeholder = 'N/A') => {
  // Check if enrichedValue is not null, empty, or 'Unknown', then return it
  if (enrichedValue && enrichedValue !== 'Unknown') {
    return enrichedValue;
  }

  // If enrichedValue is unavailable, check originalValue
  if (originalValue && originalValue !== 'Unknown') {
    return originalValue;
  }

  // If both enriched and original values are unavailable, return placeholder
  return placeholder;
};


const ProfileCardHeader = ({ company }) => {
  return (
    <div className="profile-card-header">
      <Avatar alt={company.CompanyName} src={company.ProfileImage || '/default-avatar.png'} className="profile-image" />
      <div className="header-content">
        <Typography variant="h6" className="contact-name">{company.CompanyName}</Typography>
      </div>
    </div>
  );
};


const ProfileCardInfo = ({ company }) => {
  return (
    <div className="profile-card-info">
      <Typography variant="subtitle2" className="contact-title">
        {company.CompanyName}
      </Typography>
      <Typography variant="body2" className="company-name">
        {displayEnrichedOriginalOrPlaceholderValue(company.EnrichedIndustry, company.Industry)}
      </Typography>
    </div>
  );
};


const PAGE_SIZE = 40;

const ProfileCard = React.memo(({ company, onSelectCompany }) => {
    const { codeBuddyCompanySelectedData, setCodeBuddyCompanySelectedData } = useCodeBuddyData();
    const isSelected = codeBuddyCompanySelectedData.includes(company);
  
    const handleSelection = (event) => {
        event.stopPropagation(); // Prevent triggering onSelectContact
        if (!company) return; // Guard clause to ensure contact exists
      
        const newSelectedData = isSelected
          ? codeBuddyCompanySelectedData.filter((data) => data?.SalesforceAccountID !== company?.SalesforceAccountID)
          : [...codeBuddyCompanySelectedData, company];
          setCodeBuddyCompanySelectedData(newSelectedData);
    };
  
  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <Card className={`profile-card ${isSelected ? 'selected' : ''}`} onClick={() => onSelectCompany('company', company.SalesforceAccountID)}>
        <CardContent>
          <div className="profile-card-select-icon" onClick={handleSelection}>
            {isSelected ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
          </div>
          <ProfileCardHeader company={company} />
          <ProfileCardInfo company={company} />
        </CardContent>
      </Card>
    </Grid>
  );
});



const CompaniesRow = ({ companies, onSelectCompany }) => {
  return (
    <Grid container item spacing={2} xs={12}>
      {companies.map((company) => (
        <ProfileCard key={company.SalesforceAccountID} company={company} onSelectCompany={onSelectCompany} />
      ))}
    </Grid>
  );
};


const CompanyProfileTable = ({ codeBuddyCompanies, onSelectCompany }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(codeBuddyCompanies);

  useEffect(() => {
    // Reset current data when filtered data changes (e.g., due to a filter)
    setCurrentPage(1);
    setCurrentData(filteredData.slice(0, PAGE_SIZE));
  }, [filteredData]);

  const loadMoreData = useCallback(() => {
    if (isLoading || currentData.length >= filteredData.length) return;

    setIsLoading(true);
    const nextPage = currentPage + 1;
    const newRecords = filteredData.slice(currentPage * PAGE_SIZE, nextPage * PAGE_SIZE);

    setTimeout(() => {
      setCurrentData((prevData) => [...prevData, ...newRecords]);
      setCurrentPage(nextPage);
      setIsLoading(false);
    }, 1000);
  }, [filteredData, currentPage, isLoading, currentData.length]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isLoading && currentData.length < filteredData.length) {
        loadMoreData();
      }
    }, { threshold: 0.1 });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) observer.observe(sentinel);

    return () => sentinel && observer.unobserve(sentinel);
  }, [loadMoreData, isLoading, currentData.length, filteredData.length]);

  const handleFilter = (data) => {
    setFilteredData(data);
  };

  // Group the currentData into chunks of 3 for each row
  const groupedData = [];
  for (let i = 0; i < currentData.length; i += 3) {
    groupedData.push(currentData.slice(i, i + 3));
  }

  return (
    <>
      <CompanySearchBar codeBuddyCompanies={codeBuddyCompanies} onFilter={handleFilter} />
      <Grid container spacing={2} className="profile-table">
        {groupedData.map((group, index) => (
          <CompaniesRow key={index} companies={group} onSelectCompany={onSelectCompany} />
        ))}
        <div id="sentinel" className="sentinel">
          {isLoading && <CircularProgress />}
        </div>
      </Grid>
    </>
  );
};

export default CompanyProfileTable;
