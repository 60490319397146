//LandingPage.js
import React, { useEffect, useCallback } from 'react';
import { ThemeProvider, createTheme, CssBaseline, Container, Grid, Typography, Button } from '@mui/material';
import './LandingPage.css';
import LandingHeader from './LandingHeader';
import HeroStats from './HeroStats';
import TrustedBy from './TrustedBy';
import Benefits from './Benefits';
import HowItWorks from './HowItWorks';
import CTASection from './CTASection';
import LandingFooter from './LandingFooter';
import IntegratedWith from './IntegratedWith';
import useAnalytics from './hooks/UseAnalytics';
import { PricingProvider } from './PricingContext';
import PricingSlider from './PricingSlider';
import TestimonialSlider from './TestimonialSlider';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4671D5',
    },
    secondary: {
      main: '#4A90E2',
    },
    success: {
      main: '#32b632',
    },
    background: {
      default: '#f8f9fa',
    },
  },
  typography: {
    fontFamily: "'Montserrat', 'Open Sans', Raleway",
    h1: {
      fontWeight: 800,
      fontSize: '3.6rem',
      letterSpacing: '-0.5px',
      lineHeight: 1.2,
    },
    h2: {
      fontWeight: 700,
      fontSize: '2.5rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.2rem',
    },
    body1: {
      fontSize: '1.1rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          borderRadius: '8px',
          padding: '10px 24px',
        },
      },
    },
  },
});

const LandingPage = () => {
  const { trackPageView, trackEvent } = useAnalytics();

  useEffect(() => {
    trackPageView('landing_page');

    const trackScroll = () => {
      const scrollPercentage = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
      if (scrollPercentage >= 25 && scrollPercentage < 26) trackEvent('scroll_depth_25');
      if (scrollPercentage >= 50 && scrollPercentage < 51) trackEvent('scroll_depth_50');
      if (scrollPercentage >= 75 && scrollPercentage < 76) trackEvent('scroll_depth_75');
      if (scrollPercentage >= 90 && scrollPercentage < 91) trackEvent('scroll_depth_90');
    };

    window.addEventListener('scroll', trackScroll);
    return () => window.removeEventListener('scroll', trackScroll);
  }, [trackPageView, trackEvent]);

  const handleCTAClick = useCallback(() => {
    trackEvent('cta_click', { location: 'landing_page' });
  }, [trackEvent]);

  const handleSectionView = useCallback((sectionName) => {
    trackEvent('section_view', { section: sectionName });
  }, [trackEvent]);

  const handleExternalLinkClick = useCallback((linkName) => {
    trackEvent('external_link_click', { link: linkName });
  }, [trackEvent]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PricingProvider>
        <div className="landing-page">
          <LandingHeader onNavItemClick={(item) => trackEvent('nav_item_click', { item })} />
          <IntegratedWith onView={() => handleSectionView('integrated_with')} onLogoClick={(logo) => trackEvent('integration_logo_click', { logo })} />
          <HowItWorks onView={() => handleSectionView('how_it_works')} />
          <TestimonialSlider onView={() => handleSectionView('testimonials')} />
          <CTASection onCTAClick={handleCTAClick} onView={() => handleSectionView('cta')} />
          <LandingFooter onSocialMediaClick={(platform) => trackEvent('social_media_click', { platform })} />
        </div>
      </PricingProvider>
    </ThemeProvider>
  );
};

export default LandingPage;