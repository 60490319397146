//SummaryTile.js
import React from 'react';
import '../../../styles/tiles/SummaryTile.css';

const SummaryTile = ({ title, mainMetric, subContent, icon, onClick }) => {
  return (
    <div className="summary-tile" onClick={onClick}>
      <div className="summary-tile-header">
        {icon}
        <h3>{title}</h3>
      </div>
      <div className="summary-tile-metric">
        <h1>{mainMetric}</h1>
        <p>{subContent}</p>
      </div>
    </div>
  );
};

export default SummaryTile;

