// src/components/Dashboard/MainContent.js
import React, { useState } from 'react';
import Overview from './Overview';
import CompaniesList from './CompaniesList';
import EnrichedCompaniesList from './EnrichedCompaniesList';
import ContactsList from './ContactsList';
import TargetsList from './TargetsList';
import TargetProfile from './Targets/TargetProfile';
import SubscribersList from './SubscribersList';
import '../../styles/MainContent.css';

const MainContent = ({ currentSection }) => {
  const [selectedTargetId, setSelectedTargetId] = useState(null);

  const handleTargetClick = (targetId) => {
    setSelectedTargetId(targetId);
  };

  const handleBackToTargetsList = () => {
    setSelectedTargetId(null);
  };

  return (
    <div className="main-content">
      {currentSection === 'Overview' && <Overview />}
      {currentSection === 'Companies' && <CompaniesList />}
      {currentSection === 'EnrichedCompanies' && <EnrichedCompaniesList />}
      {currentSection === 'Contacts' && <ContactsList />}
      {currentSection === 'Targets' && !selectedTargetId && (
        <TargetsList onTargetClick={handleTargetClick} />
      )}
      {currentSection === 'Targets' && selectedTargetId && (
        <TargetProfile targetId={selectedTargetId} onBack={handleBackToTargetsList} />
      )}
      {currentSection === 'Subscribers' && <SubscribersList />}
    </div>
  );
};

export default MainContent;