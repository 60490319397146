//BouncedSubscribersTile.js
import React from 'react';
import SummaryTile from './SummaryTile';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import '../../../styles/tiles/BouncedSubscribersTile.css';

const BouncedSubscribersTile = ({ bouncedSubscribers, newBouncedSubscribers, bouncePercentage, onClick }) => {
  const subContent = `${bouncePercentage}% bounced | ${newBouncedSubscribers} new`;
  return (
    <SummaryTile
      title="Bounced Subscribers"
      mainMetric={bouncedSubscribers}
      subContent={subContent}
      icon={<RemoveCircleIcon className="summary-tile-icon" />}
      onClick={onClick}
    />
  );
};

export default BouncedSubscribersTile;


