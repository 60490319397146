
// src/components/Dashboard/ContactsList.js
import React, { useState, useMemo } from 'react';
import { useAppContext } from '../../context/AppContext';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, CircularProgress, TextField } from '@mui/material';

const ContactsList = () => {
  const { contacts, fetchMoreContacts } = useAppContext();
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  const filteredContacts = useMemo(() => {
    return contacts.list.filter(contact =>
      (contact.FullName?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
      (contact.Email?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
      (contact.CompanyName?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false)
    );
  }, [contacts.list, searchTerm]);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    if ((newPage + 1) > contacts.currentPage && newPage * rowsPerPage >= contacts.list.length) {
      setLoading(true);
      await fetchMoreContacts(contacts.currentPage + 1);
      setLoading(false);
    }
  };

  if (!contacts.list.length) {
    return <CircularProgress />;
  }

  return (
    <Paper>
      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        label="Search contacts"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>LinkedIn URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredContacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((contact) => (
              <TableRow key={contact.Email}>
                <TableCell>{contact.FullName || 'N/A'}</TableCell>
                <TableCell>{contact.Email || 'N/A'}</TableCell>
                <TableCell>{contact.MobilePhone || 'N/A'}</TableCell>
                <TableCell>{contact.CompanyName || 'N/A'}</TableCell>
                <TableCell>{contact.Title || 'N/A'}</TableCell>
                <TableCell>
                  {contact.LinkedInURL ? (
                    <a href={contact.LinkedInURL} target="_blank" rel="noopener noreferrer">LinkedIn</a>
                  ) : 'N/A'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={contacts.totalContacts}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25]}
      />
      {loading && <CircularProgress />}
    </Paper>
  );
};

export default ContactsList;