//UnsubscribedSubscribersTile.js
import React from 'react';
import SummaryTile from './SummaryTile';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import '../../../styles/tiles/UnsubscribedSubscribersTile.css';

const UnsubscribedSubscribersTile = ({ unsubscribedSubscribers, newUnsubscribedSubscribers, unsubscribePercentage, onClick }) => {
  const subContent = `${unsubscribePercentage}% unsubscribed | ${newUnsubscribedSubscribers} new`;
  return (
    <SummaryTile
      title="Unsubscribed Subscribers"
      mainMetric={unsubscribedSubscribers}
      subContent={subContent}
      icon={<UnsubscribeIcon className="summary-tile-icon" />}
      onClick={onClick}
    />
  );
};

export default UnsubscribedSubscribersTile;


