// LandingHeader.js
import React from 'react';
import { Typography, Container, Box, Grid } from '@mui/material';
import LandingDemoButton from './LandingDemoButton';
import HeroStats from './HeroStats';

const LandingHeader = () => {
  return (
    <header className="landing-header modern-header">
      <Container maxWidth="lg">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box className="header-text" textAlign={{ xs: 'center', md: 'left' }}>
              <Typography variant="h1" className="main-title" gutterBottom>
                Use AI to Unlock Hidden Engagement in Your CRM
              </Typography>
              <Typography variant="h2" className="subtitle" gutterBottom>
                Boost click rates, segment high-value leads, and automate improvements to your CRM 
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
                <LandingDemoButton size="large" />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="header-image" textAlign="center">
              <img src="/landing_page_hero.png" alt="AgencyEdge Hero" className="hero-image" />
            </Box>
          </Grid>
        </Grid>
          <HeroStats />
      </Container>
    </header>
  );
};

export default LandingHeader;