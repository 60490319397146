//CompaniesTile.js
import React from 'react';
import SummaryTile from './SummaryTile';
import BusinessIcon from '@mui/icons-material/Business';
import '../../../styles/tiles/CompaniesTile.css';

const CompaniesTile = ({ totalCompanies, newCompanies, onClick }) => {
  const subContent = `${newCompanies} new this month`;
  return (
    <SummaryTile
      title="Companies"
      mainMetric={totalCompanies}
      subContent={subContent}
      icon={<BusinessIcon className="summary-tile-icon" />}
      onClick={onClick}
    />
  );
};

export default CompaniesTile;



