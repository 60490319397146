// UserAuthenticationDataContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

export const UserAuthenticationDataContext = createContext();

export const useUserAuthenticationData = () => useContext(UserAuthenticationDataContext);

export const UserAuthenticationDataProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userFirstName, setUserFirstName] = useState(localStorage.getItem('userFirstName') || '');


  useEffect(() => {
    console.log('UserAuthenticationDataProvider: isAuthenticated changed to', isAuthenticated);
  }, [isAuthenticated]);


  return (
    <UserAuthenticationDataContext.Provider value={{ isAuthenticated, setIsAuthenticated, userFirstName, setUserFirstName }}>
      {children}
    </UserAuthenticationDataContext.Provider>
  );
};
