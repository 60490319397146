// src/components/Dashboard/SubscribersList.js
import React, { useState, useMemo } from 'react';
import { useAppContext } from '../../context/AppContext';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, CircularProgress, TextField } from '@mui/material';

const SubscribersList = () => {
  const { subscribers, fetchMoreSubscribers } = useAppContext();
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  const filteredSubscribers = useMemo(() => {
    return subscribers.list.filter(subscriber =>
      (subscriber.Email?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
      (subscriber.Name?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
      (subscriber.State?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false)
    );
  }, [subscribers.list, searchTerm]);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    if ((newPage + 1) > subscribers.currentPage && newPage * rowsPerPage >= subscribers.list.length) {
      setLoading(true);
      await fetchMoreSubscribers(subscribers.currentPage + 1);
      setLoading(false);
    }
  };

  if (!subscribers.list.length) {
    return <CircularProgress />;
  }

  return (
    <Paper>
      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        label="Search subscribers"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Last Modified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSubscribers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((subscriber) => (
              <TableRow key={subscriber.ProjectSubscriberID}>
                <TableCell>{subscriber.Email || 'N/A'}</TableCell>
                <TableCell>{subscriber.Name || 'N/A'}</TableCell>
                <TableCell>{subscriber.State || 'N/A'}</TableCell>
                <TableCell>{subscriber.CreatedAt ? new Date(subscriber.CreatedAt).toLocaleString() : 'N/A'}</TableCell>
                <TableCell>{subscriber.LastModified ? new Date(subscriber.LastModified).toLocaleString() : 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={subscribers.totalSubscribers}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25]}
      />
      {loading && <CircularProgress />}
    </Paper>
  );
};

export default SubscribersList;