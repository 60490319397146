//TestimonialSlider.js
import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import Slider from 'react-slick';
import { motion, AnimatePresence } from 'framer-motion';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonials = [
  {
    name: 'Heather Elder',
    company: 'Heather Elder Represents',
    content: "AgencyEdge has revolutionized our approach to CRM. The data enrichment feature has given us invaluable insights into our clients.",
    avatar: '/HeatherElder.jpg',
  },
  {
    name: 'Laurane LoSpalluto',
    company: 'Heather Elder Represents',
    content: "The lead generation capabilities of AgencyEdge have significantly boosted our sales pipeline. It's been a game-changer for our agency!",
    avatar: '/LauranneLospalluto.jpg',
  },
  {
    name: 'Emily Lang',
    company: 'Heather Elder Represents',
    content: "Integrating AgencyEdge with our existing systems was seamless. The AI-driven segmentation has allowed us to target our campaigns with unprecedented precision.",
    avatar: '/EmilyLang.jpg',
  },
];

const TestimonialSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <Box sx={{ maxWidth: '800px', margin: 'auto', textAlign: 'center', py: 4 }}>
      <Typography variant="h4" gutterBottom>
        What Our Customers Say
      </Typography>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <AnimatePresence key={index}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
            >
              <Box sx={{ p: 3 }}>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <Avatar
                    src={testimonial.avatar}
                    alt={`${testimonial.name}'s avatar`}
                    sx={{ width: 80, height: 80, margin: 'auto', mb: 2 }}
                  />
                </motion.div>
                <Typography variant="body1" gutterBottom>
                  "{testimonial.content}"
                </Typography>
                <Typography variant="subtitle1" color="primary">
                  {testimonial.name}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  {testimonial.company}
                </Typography>
              </Box>
            </motion.div>
          </AnimatePresence>
        ))}
      </Slider>
    </Box>
  );
};

export default TestimonialSlider;