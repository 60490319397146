// src/components/Dashboard/CompaniesList.js
import React, { useState, useMemo } from 'react';
import { useAppContext } from '../../context/AppContext';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, CircularProgress, TextField } from '@mui/material';

const CompaniesList = () => {
  const { companies, fetchMoreCompanies } = useAppContext();
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  const filteredCompanies = useMemo(() => {
    return companies.list.filter(company =>
      (company.CompanyName?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
      (company.Email?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false)
    );
  }, [companies.list, searchTerm]);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    if ((newPage + 1) > companies.currentPage && newPage * rowsPerPage >= companies.list.length) {
      setLoading(true);
      await fetchMoreCompanies(companies.currentPage + 1);
      setLoading(false);
    }
  };

  if (!companies.list.length) {
    return <CircularProgress />;
  }

  return (
    <Paper>
      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        label="Search companies"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell>Website</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>LinkedIn URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCompanies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((company) => (
              <TableRow key={company.CompanyName}>
                <TableCell>{company.CompanyName || 'N/A'}</TableCell>
                <TableCell>{company.CompanyWebsite || 'N/A'}</TableCell>
                <TableCell>{company.Email || 'N/A'}</TableCell>
                <TableCell>{company.Phone || 'N/A'}</TableCell>
                <TableCell>
                  {company.LinkedInURL ? (
                    <a href={company.LinkedInURL} target="_blank" rel="noopener noreferrer">LinkedIn</a>
                  ) : 'N/A'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={companies.totalCompanies}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25]}
      />
      {loading && <CircularProgress />}
    </Paper>
  );
};

export default CompaniesList;
