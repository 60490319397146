// SalesforceDataContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const SalesforceDataContext = createContext(null);

export const useSalesforceData = () => useContext(SalesforceDataContext);

export const SalesforceDataProvider = ({ children }) => {
  const [salesforceData, setSalesforceData] = useState(null);
  const [salesforceServiceInfo, setSalesforceServiceInfo] = useState(null);
  const [dataReceiverServiceInfo, setDataReceiverServiceInfo] = useState(null);
  const [salesforceContacts, setSalesforceContacts] = useState(null);
  const [salesforceAccounts, setSalesforceAccounts] = useState(null);
  const [salesforceUpdateReady, setSalesforceUpdateReady] = useState(false);
  const [isSalesforceUpdating, setIsSalesforceUpdating] = useState(false);
  const [isSalesforceUpdated, setIsSalesforceUpdated] = useState(null);
  const [salesforceConfig, setSalesforceConfig] = useState(null);
  const [salesforceCompanies, setSalesforceCompanies] = useState(null);
  const [salesforceIsConnected, setSalesforceIsConnected] = useState(false);
  const [isSalesforceProcessing, setIsSalesforceProcessing] = useState(false);

  useEffect(() => {
    // Only fetch salesforceConfig if it's not already set
    if (!salesforceConfig) {
      const projectId = '1070';
      fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/configurations/get-salesforce-config/${projectId}`)
        .then(response => response.json())
        .then(data => setSalesforceConfig(data))
        .catch(error => console.error('Error fetching Salesforce config:', error));
    }
  }, [salesforceConfig]); // Add salesforceConfig as a dependency

  const handleLogout = () => {
    localStorage.removeItem('salesforce_access_token');
    localStorage.removeItem('salesforce_instance_url');
    localStorage.removeItem('pkce_code_verifier');
  
    setSalesforceData(null);
    setSalesforceContacts(null);
    setSalesforceAccounts(null);
    setSalesforceServiceInfo(null);
    setDataReceiverServiceInfo(null);
    setIsSalesforceUpdating(false);
    setSalesforceUpdateReady(false);
    setIsSalesforceUpdated(false);
    setSalesforceIsConnected(false);
  };

  return (
    <SalesforceDataContext.Provider value={{ salesforceContacts, setSalesforceContacts, salesforceAccounts, setSalesforceAccounts, salesforceServiceInfo, setSalesforceServiceInfo, dataReceiverServiceInfo, setDataReceiverServiceInfo, salesforceData, setSalesforceData, isSalesforceUpdating, setIsSalesforceUpdating, salesforceUpdateReady, setSalesforceUpdateReady, isSalesforceUpdated, setIsSalesforceUpdated, handleLogout, salesforceConfig, setSalesforceConfig, salesforceCompanies, setSalesforceCompanies, salesforceIsConnected, setSalesforceIsConnected, isSalesforceProcessing, setIsSalesforceProcessing }}>
      {children}
    </SalesforceDataContext.Provider>
  );
};
