// SalesforceRedirect.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSalesforceData } from './SalesforceDataContext';
import './SalesforceRedirect.css';
import { useUserAuthenticationData } from './UserAuthenticationDataContext';
import { useCustomCompanyData } from './CustomCompanyDataContext';
import { useLinkedInData } from './LinkedInDataContext';
import { useCampaignMonitorData } from './CampaignMonitorDataContext';



function SalesforceRedirect({ setIsAuthenticated }) {
  const [isLoading, setIsLoading] = useState(true); // Start with loading true
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize navigate
  const { setSalesforceData, salesforceConfig, setDataSourceServiceInfo, dataSourceServiceInfo, salesforceIsConnected, setSalesforceIsConnected } = useSalesforceData();
  const { setUserFirstName } = useUserAuthenticationData();
  const { setCustomCompanyAnymailfinderData } = useCustomCompanyData();
  const { linkedInIsConnected, setLinkedInIsConnected } = useLinkedInData();
  const { campaignMonitorIsConnected, setCampaignMonitorIsConnected } = useCampaignMonitorData();



  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    // store linkedInIsConnected and campaignMonitorIsConnected in localStorage
    localStorage.setItem('linkedInIsConnected', linkedInIsConnected);
    localStorage.setItem('campaignMonitorIsConnected', campaignMonitorIsConnected);
    

    if (code) {
      completeSalesforceAuthentication(code);
    } else {
      setIsLoading(false);
    }
  }, []);


  const completeSalesforceAuthentication = async (code) => {
    const codeVerifier = localStorage.getItem('pkce_code_verifier');
    const userId = localStorage.getItem('userId'); // Get UserId from localStorage

    if (!userId) {
      setError('User ID is not available');
      navigate('/'); // Navigate to home page on error
      return;
    }


    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/salesforce-callback`;
    console.log("API URL:", apiUrl);

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              'UserId': userId, // Add UserId to the request headers
          },
            body: JSON.stringify({ code, codeVerifier }), // Corrected from 'stringiy' to 'stringify'
        });



        const data = await response.json();

        if (data.status === 'success') {
            localStorage.setItem('salesforce_access_token', data.access_token);
            localStorage.setItem('salesforce_instance_url', data.instance_url);
            setIsAuthenticated(true); // Moved this line after the API call
            setUserFirstName(localStorage.getItem('userFirstName')); // Set userFirstName again
            setSalesforceIsConnected(true); // Set salesforceIsConnected to true

            // setSalesforceIsConnected to localStorage
            localStorage.setItem('salesforceIsConnected', true);

            // get linkedInIsConnected and campaignMonitorIsConnected from localStorage
            const linkedInIsConnected = localStorage.getItem('linkedInIsConnected');
            const campaignMonitorIsConnected = localStorage.getItem('campaignMonitorIsConnected');

            // setLinkedInIsConnected and setCampaignMonitorIsConnected to true if they are true. Otherwise, set them to false
            setLinkedInIsConnected(linkedInIsConnected === 'true');
            setCampaignMonitorIsConnected(campaignMonitorIsConnected === 'true');



            navigate('/'); // Navigate to home page on success
        } else {
            setError('Failed to authenticate with Salesforce.');
            navigate('/'); // Navigate to home page on error
        }
    } catch (err) {
        console.error('Error:', err);
        setError('An error occurred while connecting to Salesforce.');
        navigate('/'); // Navigate to home page on catch
    } finally {
        setIsLoading(false);
    }
  };


  

  if (isLoading) {
    return <div className="salesforce-redirect-container">Connecting to Salesforce...</div>;
  }

  if (error) {
    return <div className="salesforce-redirect-container error">{error}</div>;
  }

  return null; // Render nothing if not loading or error
}

export default SalesforceRedirect;




