// AnymailfinderDataContext.js
import React, { createContext, useState, useContext } from 'react';

const AnymailfinderDataContext = createContext(null);

export const useAnymailfinderData = () => useContext(AnymailfinderDataContext);

export const AnymailfinderDataProvider = ({ children }) => {
  const [anymailfinderData, setAnymailfinderData] = useState(null);
  const [anymailfinderStatistics, setAnymailfinderStatistics] = useState(null);
  const [anymailfinderProcessFinished, setAnymailfinderProcessFinished] = useState(false);
  const [dataProcessorServiceInfo, setDataProcessorServiceInfo] = useState(null);
  const [anymailfinderSalesforceProcessReady, setAnymailfinderSalesforceProcessReady] = useState(false); // New state
  const [anymailfinderOpenAIProcessReady, setAnymailfinderOpenAIProcessReady] = useState(false); // New state
  const [isAnymailfinderProcessing, setIsAnymailfinderProcessing] = useState(false);
  const [anymailfinderNewEmails, setAnymailfinderNewEmails] = useState([]);
  const [anymailfinderSalesforceData, setAnymailfinderSalesforceData] = useState([]); // New state
  const [anymailfinderOpenAIData, setAnymailfinderOpenAIData] = useState([]); // New state
  const [selectedAnymailfinderRows, setSelectedAnymailfinderRows] = useState(new Set());
  const [anymailfinderServiceInfo, setAnymailfinderServiceInfo] = useState(null); // New state
  const [anymailfinderProcessReady, setAnymailfinderProcessReady] = useState(false); // New state
  const [anymailfinderNewCompanies, setAnymailfinderNewCompanies] = useState([]); // New state


  return (
    <AnymailfinderDataContext.Provider value={{ anymailfinderData, setAnymailfinderData, dataProcessorServiceInfo, setDataProcessorServiceInfo, anymailfinderSalesforceProcessReady, setAnymailfinderSalesforceProcessReady, anymailfinderOpenAIProcessReady, setAnymailfinderOpenAIProcessReady, isAnymailfinderProcessing, setIsAnymailfinderProcessing, anymailfinderNewEmails, setAnymailfinderNewEmails, anymailfinderSalesforceData, setAnymailfinderSalesforceData, anymailfinderOpenAIData, setAnymailfinderOpenAIData, selectedAnymailfinderRows, setSelectedAnymailfinderRows, anymailfinderStatistics, setAnymailfinderStatistics, anymailfinderProcessFinished, setAnymailfinderProcessFinished, anymailfinderServiceInfo, setAnymailfinderServiceInfo, anymailfinderProcessReady, setAnymailfinderProcessReady, anymailfinderNewCompanies, setAnymailfinderNewCompanies }}>
      {children}
    </AnymailfinderDataContext.Provider>
  );
};
