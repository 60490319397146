
// src/components/Blog/BlogPostPage.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { 
  Container, Typography, Box, Chip, Divider, Paper, Skeleton,
  Breadcrumbs, Fade, IconButton, Tooltip, Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CalendarToday, Person, ArrowBack } from '@mui/icons-material';
import axios from 'axios';
import useAnalytics from '../../hooks/UseAnalytics';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  boxShadow: theme.shadows[2],
  borderRadius: theme.shape.borderRadius * 2,  // Enhance with rounded corners
  backgroundColor: theme.palette.background.default,  // Subtle background color
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6),
  },
}));

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiBreadcrumbs-separator': {
    color: theme.palette.text.secondary,  // Subtle separator color
  },
}));

const PostContent = styled('div')(({ theme }) => ({
  '& h1, h2, h3, h4, h5, h6': {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  '& p': {
    marginBottom: theme.spacing(2),
    lineHeight: 1.7,
    color: theme.palette.text.secondary,
  },
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const BlogPostPage = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/fetch_blog_post/${slug}`);
        setPost(response.data);
        setLoading(false);
        trackPageView(`blog_post_${slug}`);
      } catch (error) {
        console.error('Error fetching blog post:', error);
        setError('Failed to fetch blog post. Please try again later.');
        setLoading(false);
      }
    };
    fetchPost();
  }, [slug, trackPageView]);

  if (error) return (
    <Typography color="error" align="center" variant="h6" sx={{ mt: 4 }}>
      {error}
    </Typography>
  );

  return (
    <Fade in={!loading}>
      <Container maxWidth="md">
        {post && (
          <Helmet>
            <title>{post.seo.seoTitle || post.title} - AgencyEdge Blog</title>
            <meta name="description" content={post.seo.seoDescription || post.metaDescription} />
            <meta property="og:title" content={post.seo.ogTitle || post.title} />
            <meta property="og:description" content={post.seo.ogDescription || post.metaDescription} />
            <meta property="og:image" content={post.seo.ogImage || post.featuredImage} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={post.seo.twitterTitle || post.title} />
            <meta name="twitter:description" content={post.seo.twitterDescription || post.metaDescription} />
            <meta name="twitter:image" content={post.seo.twitterImage || post.featuredImage} />
            <link rel="canonical" href={post.seo.canonicalURL} />
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BlogPosting",
                "headline": post.title,
                "image": post.featuredImage,
                "datePublished": post.publishedAt,
                "dateModified": post.publishedAt,
                "author": {
                  "@type": "Person",
                  "name": post.authorName
                }
              })}
            </script>
          </Helmet>
        )}
        <Box my={4}>
          <StyledBreadcrumbs aria-label="breadcrumb">
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>Home</Link>
            <Link to="/blog" style={{ textDecoration: 'none', color: 'inherit' }}>Blog</Link>
            <Typography color="textPrimary">{post ? post.title : 'Loading...'}</Typography>
          </StyledBreadcrumbs>
        </Box>
        <StyledPaper elevation={0}>
          {loading ? (
            <>
              <Skeleton variant="text" height={60} width="80%" />
              <Skeleton variant="text" height={24} width="40%" />
              <Skeleton variant="rectangular" height={300} sx={{ my: 2 }} />
              <Skeleton variant="text" height={20} />
              <Skeleton variant="text" height={20} />
              <Skeleton variant="text" height={20} width="80%" />
            </>
          ) : (
            <>
              <Typography variant="h3" component="h1" gutterBottom>
                {post.title}
              </Typography>
              <Box display="flex" alignItems="center" mb={2}>
                <Tooltip title="Publication date">
                  <Box display="flex" alignItems="center" mr={2}>
                    <CalendarToday fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography variant="body2" color="textSecondary">
                      {new Date(post.publishedAt).toLocaleDateString()}
                    </Typography>
                  </Box>
                </Tooltip>
                <Tooltip title="Author">
                  <Box display="flex" alignItems="center">
                    <Person fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography variant="body2" color="textSecondary">
                      {post.authorName}
                    </Typography>
                  </Box>
                </Tooltip>
              </Box>
              <Box mb={2}>
                {post.categories.map((category) => (
                  <Chip 
                    key={category} 
                    label={category} 
                    variant="outlined" 
                    size="small" 
                    sx={{ 
                      mr: 1, 
                      mb: 1,
                      backgroundColor: 'rgba(0,0,0,0.05)',
                      color: 'rgba(0,0,0,0.7)',
                      '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0.1)',
                      },
                    }} 
                  />
                ))}
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box my={4}>
                <PostContent dangerouslySetInnerHTML={{ __html: post.content }} />
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box my={2}>
                {post.tags.map((tag) => (
                  <Chip 
                    key={tag} 
                    label={tag} 
                    size="small" 
                    sx={{ 
                      mr: 1, 
                      mb: 1,
                      backgroundColor: 'rgba(0,0,0,0.05)',
                      color: 'rgba(0,0,0,0.7)',
                      '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0.1)',
                      },
                    }} 
                  />
                ))}
              </Box>
            </>
          )}
        </StyledPaper>
        <Box display="flex" justifyContent="center" my={4}>
          <Tooltip title="Back to blog list">
            <IconButton component={Link} to="/blog" color="primary" size="large">
              <ArrowBack />
            </IconButton>
          </Tooltip>
        </Box>
      </Container>
    </Fade>
  );
};

export default BlogPostPage;
