//Pricing.js
import React, { useState, lazy, Suspense, useEffect, useCallback } from 'react';
import { Container, Typography, Box, CircularProgress, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import { PricingProvider } from './PricingContext';
import PricingHeader from './PricingHeader';
import PricingSlider from './PricingSlider';
import PricingPlans from './PricingPlans';
import RequestDemoForm from './RequestDemoForm';
import useAnalytics from './hooks/UseAnalytics';
import './Pricing.css';

const PricingComparison = lazy(() => import('./PricingComparison'));
const TestimonialSlider = lazy(() => import('./TestimonialSlider'));

const Pricing = React.memo(() => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDemoPlan, setSelectedDemoPlan] = useState('');
  const { trackPageView, trackEvent, logError } = useAnalytics();

  useEffect(() => {
    trackPageView('pricing');
  }, [trackPageView]);

  const handleError = useCallback((error) => {
    logError('Error in Pricing component', error);
  }, [logError]);

  const handleRequestDemo = useCallback((plan) => {
    setSelectedDemoPlan(plan);
    setIsDialogOpen(true);
    trackEvent('demo_request_initiated', { plan });
  }, [trackEvent]);

  const handleSliderChange = useCallback((value) => {
    trackEvent('pricing_slider_change', { value });
  }, [trackEvent]);

  const handlePlanHover = useCallback((plan) => {
    trackEvent('pricing_plan_hover', { plan });
  }, [trackEvent]);

  const handlePlanFeatureClick = useCallback((plan, feature) => {
    trackEvent('plan_feature_click', { plan, feature });
  }, [trackEvent]);


  return (
    <PricingProvider>
      <Container maxWidth="lg" className="pricing-container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <PricingHeader>
            <PricingSlider context="pricing" onSliderChange={handleSliderChange} />
          </PricingHeader>
          <PricingPlans 
            onRequestDemo={handleRequestDemo} 
            onPlanHover={handlePlanHover}
            onFeatureClick={handlePlanFeatureClick}
          />
          <Suspense fallback={<CircularProgress />}>
            <ErrorBoundary onError={handleError}>
              <Box my={4}>
                <TestimonialSlider onTestimonialView={(testimonialId) => trackEvent('testimonial_view', { testimonialId })} />
              </Box>
              <Box my={4}>
                <PricingComparison 
                  onFeatureHover={(feature) => trackEvent('pricing_feature_hover', { feature })}
                  onFeatureClick={(feature) => trackEvent('pricing_feature_click', { feature })}
                />
              </Box>
            </ErrorBoundary>
          </Suspense>
        </motion.div>
        <RequestDemoForm
          open={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
            trackEvent('demo_form_closed', { plan: selectedDemoPlan });
          }}
          plan={selectedDemoPlan}
        />
      </Container>
    </PricingProvider>
  );
});


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.props.onError(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Typography color="error">Something went wrong. Please try again later.</Typography>;
    }

    return this.props.children;
  }
}

export default Pricing;