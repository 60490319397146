//PricingPlans.js
import React from 'react';
import { Grid, Typography, Box, Tooltip } from '@mui/material';
import PricingCard from './PricingCard';
import { usePricing } from './PricingContext';
import { styled } from '@mui/system';
import { motion, AnimatePresence } from 'framer-motion';
import BillingCycleToggle from './BillingCycleToggle';

const ToggleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const SavingsBadge = styled(motion.div)(({ theme }) => ({
  background: theme.palette.success.main,
  color: theme.palette.common.white,
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  fontWeight: 'bold',
  marginLeft: theme.spacing(2),
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
}));

// Define the base prices for each feature (per 30,000 contacts)
const FEATURE_PRICES = {
  Enrichment: 8000,
  'Lead Generation': 4000,
  'AI Segmentation': 5000,
  Integration: 2000,
};

const PricingPlans = ({ onRequestDemo }) => {
  const { crmSize, billingCycle, setBillingCycle } = usePricing();

  const calculatePrice = (features) => {
    const scaleFactor = crmSize / 30000;
    const annualPrice = features.reduce((total, feature) => {
      return total + (FEATURE_PRICES[feature] || 0) * scaleFactor;
    }, 0);

    const monthlyPrice = annualPrice / 12;
    const annualDiscountedPrice = monthlyPrice * 0.8;

    return {
      monthly: Math.round(monthlyPrice),
      annual: Math.round(annualDiscountedPrice),
      savings: Math.round(monthlyPrice * 0.2 * 12),
    };
  };

  const allFeatures = ['Enrichment', 'Lead Generation', 'AI Segmentation', 'Integration'];
  const basePlanFeatures = ['Enrichment', 'Integration'];
  const proPlanFeatures = ['Enrichment', 'Lead Generation', 'AI Segmentation', 'Integration'];

  const basePlanPrice = calculatePrice(basePlanFeatures);
  const proPlanPrice = calculatePrice(proPlanFeatures);

  const handleBillingCycleChange = (cycle) => {
    setBillingCycle(cycle);
  };

  return (
    <Box className="pricing-plans-container">
      <ToggleContainer>
        <BillingCycleToggle billingCycle={billingCycle} onChange={handleBillingCycleChange} />
        <AnimatePresence>
          {billingCycle === 'annual' && (
            <SavingsBadge
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ type: 'spring', stiffness: 300, damping: 25 }}
            >
              <Tooltip title="Save 20% with annual billing" arrow>
                <Typography variant="body2">Save 20%</Typography>
              </Tooltip>
            </SavingsBadge>
          )}
        </AnimatePresence>
      </ToggleContainer>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6}>
          <PricingCard
            title="Base Plan"
            price={basePlanPrice}
            billingCycle={billingCycle}
            features={basePlanFeatures}
            allFeatures={allFeatures}
            crmSize={crmSize}
            onRequestDemo={onRequestDemo}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PricingCard
            title="Pro Plan"
            price={proPlanPrice}
            billingCycle={billingCycle}
            features={proPlanFeatures}
            allFeatures={allFeatures}
            crmSize={crmSize}
            isPro
            onRequestDemo={onRequestDemo}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PricingPlans;