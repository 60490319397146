// ContactProfileHeader.js

import React from 'react';
import { Typography, Avatar, Link, ToolTip } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import './ProfileHeader.css';

const ContactProfileHeader = ({ contact }) => {
  const isValidLink = (url) => url && url !== 'Unknown' && url.trim() !== '';
  const displayEnrichedOriginalOrPlaceholderValue = (enrichedValue, originalValue, placeholder = 'None') => {
    // Check if enrichedValue is not null, empty, or 'Unknown', then return it
    if (enrichedValue && enrichedValue !== 'Unknown') {
      return enrichedValue;
    }
  
    // If enrichedValue is unavailable, check originalValue
    if (originalValue && originalValue !== 'Unknown') {
      return originalValue;
    }
  
    // If both enriched and original values are unavailable, return placeholder
    return placeholder;
  };

  return (
    <div className="contact-header">
      <Avatar alt={contact.FullName} src={contact.ProfileImage} className="profile-avatar" />
      <div className="contact-info">
        <div className="name-and-linkedin">
          <Typography variant="h4" className="contact-name">{contact.FullName}</Typography>
          {isValidLink(contact.EnrichedLinkedInURL) && (
            <Link href={contact.EnrichedLinkedInURL} target="_blank" rel="noopener noreferrer" className="linkedin-icon-large">
              <LinkedInIcon fontSize="large" />
            </Link>
          )}
        </div>
        <Typography variant="subtitle1" className="company-name">{displayEnrichedOriginalOrPlaceholderValue(contact.EnrichedCompanyName, contact.CompanyName)}</Typography>
        <Typography variant="subtitle2" className="contact-title">{displayEnrichedOriginalOrPlaceholderValue(contact.EnrichedTitle, contact.Title)}</Typography>
        <Typography variant="subtitle2" className="contact-email">{displayEnrichedOriginalOrPlaceholderValue(contact.EnrichedEmail, contact.Email)}</Typography>
      </div>
    </div>
  );
};

export default ContactProfileHeader;