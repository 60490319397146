// src/components/Dashboard/EnrichedCompanies/EnrichedCompanySocialIcons.js
import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import '../../../styles/EnrichedCompanies/EnrichedCompanySocialIcons.css';

const socialIcons = [
  { name: 'LinkedIn', key: 'LinkedInURL', icon: 'a1_linkedin_social_icon.png' },
  { name: 'Instagram', key: 'InstagramURL', icon: 'a1_instagram_social_icon.png' },
  { name: 'Facebook', key: 'FacebookURL', icon: 'a1_facebook_social_icon.png' },
  { name: 'Twitter', key: 'TwitterURL', icon: 'a1_twitter_social_icon.png' },
  { name: 'TikTok', key: 'TikTokURL', icon: 'a1_tiktok_social_icon.png' },
  { name: 'Vimeo', key: 'VimeoURL', icon: 'a1_vimeo_social_icon.png' },
];

const EnrichedCompanySocialIcons = ({ socialData }) => {
  return (
    <Box className="social-icons-container">
      {socialIcons.map((social) => (
        socialData[social.key] && (
          <Tooltip key={social.name} title={social.name} arrow>
            <IconButton
              className="social-icon-button"
              onClick={() => window.open(socialData[social.key], '_blank')}
            >
              <img
                src={`/${social.icon}`}
                alt={`${social.name} icon`}
                className="social-icon"
              />
            </IconButton>
          </Tooltip>
        )
      ))}
    </Box>
  );
};

export default EnrichedCompanySocialIcons;