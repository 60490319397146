// CampaignMonitorDataContext.js
import React, { createContext, useState, useContext } from 'react';

const CampaignMonitorDataContext = createContext(null);

export const useCampaignMonitorData = () => useContext(CampaignMonitorDataContext);

export const CampaignMonitorDataProvider = ({ children }) => {
  const [campaignMonitorCampaignData, setCampaignMonitorCampaignData] = useState(null);
  const [campaignMonitorStatistics, setCampaignMonitorStatistics] = useState(null);
  const [campaignMonitorProcessFinished, setCampaignMonitorProcessFinished] = useState(false);
  const [campaignMonitorProcessReady, setCampaignMonitorProcessReady] = useState(false); // New state
  const [isCampaignMonitorProcessing, setIsCampaignMonitorProcessing] = useState(false);
  const [campaignMonitorSalesforceData, setCampaignMonitorSalesforceData] = useState([]); // New state
  const [selectedCampaignMonitorRows, setSelectedCampaignMonitorRows] = useState(new Set());
  const [campaignMonitorNewEmails, setCampaignMonitorNewEmails] = useState([]); // New state
  const [campaignMonitorCampaignServiceInfo, setCampaignMonitorCampaignServiceInfo] = useState(null); // New state
  const [campaignMonitorCampaigns, setCampaignMonitorCampaigns] = useState(null);
  const [campaignMonitorRecipients, setCampaignMonitorRecipients] = useState(null);
  const [campaignMonitorOpens, setCampaignMonitorOpens] = useState(null);
  const [campaignMonitorClicks, setCampaignMonitorClicks] = useState(null);
  const [campaignMonitorBounces, setCampaignMonitorBounces] = useState(null);
  const [campaignMonitorUnsubscribes, setCampaignMonitorUnsubscribes] = useState(null);
  const [campaignMonitorIsConnected, setCampaignMonitorIsConnected] = useState(false);


  return (
    <CampaignMonitorDataContext.Provider value={{ campaignMonitorCampaignData, setCampaignMonitorCampaignData, campaignMonitorProcessReady, setCampaignMonitorProcessReady, isCampaignMonitorProcessing, setIsCampaignMonitorProcessing, campaignMonitorSalesforceData, setCampaignMonitorSalesforceData, selectedCampaignMonitorRows, setSelectedCampaignMonitorRows, campaignMonitorStatistics, setCampaignMonitorStatistics, campaignMonitorProcessFinished, setCampaignMonitorProcessFinished, campaignMonitorNewEmails, setCampaignMonitorNewEmails, campaignMonitorCampaignServiceInfo, setCampaignMonitorCampaignServiceInfo, campaignMonitorCampaigns, setCampaignMonitorCampaigns, campaignMonitorOpens, setCampaignMonitorOpens, campaignMonitorClicks, setCampaignMonitorClicks, campaignMonitorBounces, setCampaignMonitorBounces, campaignMonitorUnsubscribes, setCampaignMonitorUnsubscribes, campaignMonitorRecipients, setCampaignMonitorRecipients, campaignMonitorIsConnected, setCampaignMonitorIsConnected }}>
      {children}
    </CampaignMonitorDataContext.Provider>
  );
};
