//BillingCycleToggle.js
import React from 'react';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

const ToggleContainer = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '34px',
  padding: '0px',
  cursor: 'pointer',
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  position: 'relative',
  overflow: 'hidden',
  width: '200px', // Fixed width
}));

const ToggleOption = styled('div')(({ theme }) => ({
  padding: '8px 16px',
  borderRadius: '30px',
  zIndex: 1,
  transition: 'color 0.3s ease',
  flex: 1,
  textAlign: 'center',
}));

const Slider = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  top: '4px',
  height: 'calc(100% - 8px)',
  width: '50%', // Fixed width
  borderRadius: '30px',
  backgroundColor: theme.palette.primary.main,
  zIndex: 0,
}));

const BillingCycleToggle = ({ billingCycle, onChange }) => {
  const isAnnual = billingCycle === 'annual';

  return (
    <ToggleContainer onClick={() => onChange(isAnnual ? 'monthly' : 'annual')}>
      <ToggleOption style={{ color: !isAnnual ? '#fff' : 'inherit' }}>Monthly</ToggleOption>
      <ToggleOption style={{ color: isAnnual ? '#fff' : 'inherit' }}>Annual</ToggleOption>
      <Slider
        initial={false}
        animate={{
          x: isAnnual ? '100%' : '0%',
        }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      />
    </ToggleContainer>
  );
};

export default BillingCycleToggle;