// UserAuthenticationScreen.js
import React from 'react';
import CodeBuddyApp from './CodeBuddyApp'; // Assuming CodeBuddyApp is the central app component
import UserAuthenticationLogin from './UserAuthenticationLogin';

const UserAuthenticationScreen = () => {
  return (
    <div className="user-authentication-screen">
      <UserAuthenticationLogin />
    </div>
  );
};

export default UserAuthenticationScreen;