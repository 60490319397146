// CampaignMonitorRedirect.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SalesforceRedirect.css'; // Ensure you have the corresponding CSS
import { useUserAuthenticationData } from './UserAuthenticationDataContext';
import { useCampaignMonitorData } from './CampaignMonitorDataContext';
import { useSalesforceData } from './SalesforceDataContext';
import { useLinkedInData } from './LinkedInDataContext';

function CampaignMonitorRedirect({ setIsAuthenticated }) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setUserFirstName } = useUserAuthenticationData();
  const { campaignMonitorIsConnected, setCampaignMonitorIsConnected } = useCampaignMonitorData();
  const { linkedInIsConnected, setLinkedInIsConnected } = useLinkedInData();
  const { salesforceIsConnected, setSalesforceIsConnected } = useSalesforceData();



  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const campaignMonitorState = urlParams.get('state');
    const storedState = localStorage.getItem('campaign_monitor_auth_state');  // Retrieve the stored state parameter

    console.log("Code inside CampaignMonitorRedirect.js useEffect:", code);
    console.log("Campaign Monitor State inside CampaignMonitorRedirect.js useEffect:", campaignMonitorState);
    console.log("Stored State inside CampaignMonitorRedirect.js useEffect:", storedState);

    if (code && campaignMonitorState === storedState) {
        completeCampaignMonitorAuthentication(code);
    } else {
      setIsLoading(false);
      setError('Invalid state parameter');
      console.log(error);
      console.log("Error inside CampaignMonitorRedirect.js useEffect:", error);
    }
  }, []);

  const completeCampaignMonitorAuthentication = async (code) => {
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/campaign-monitor/campaign-monitor-callback`;

    console.log ("API URL inside completeCampaignMonitorAuthentication:", apiUrl);
    console.log("Code inside completeCampaignMonitorAuthentication:", code);

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    'UserId': localStorage.getItem('userId') },
        body: JSON.stringify({ code }),
      });

      console.log("Response inside completeCampaignMonitorAuthentication:", response);

      const data = await response.json();

      if (data.status === 'success') {
        localStorage.setItem('campaign_monitor_access_token', data.access_token);
        setIsAuthenticated(true);
        setUserFirstName(localStorage.getItem('userFirstName')); // Set userFirstName again
        setCampaignMonitorIsConnected(true);

        // setCampaignMonitorIsConnected to localStorage
        localStorage.setItem('campaignMonitorIsConnected', campaignMonitorIsConnected);

        // get linkedInIsConnected and salesforceIsConnected from localStorage
        const linkedInIsConnected = localStorage.getItem('linkedInIsConnected');
        const salesforceIsConnected = localStorage.getItem('salesforceIsConnected');

        // setLinkedInIsConnected and setSalesforceIsConnected to true if they are true. Otherwise, set them to false
        setLinkedInIsConnected(linkedInIsConnected === 'true');
        setSalesforceIsConnected(salesforceIsConnected === 'true');


        navigate('/'); // Navigate to home or another page on success
      } else {
        setError('Failed to authenticate with Campaign Monitor.');
      }
    } catch (err) {
      console.error('Error:', err);
      setError('An error occurred while connecting to Campaign Monitor.');
      navigate('/'); // Navigate to home page on catch
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div className="campaign-monitor-redirect-container">Connecting to Campaign Monitor...</div>;
  }

  if (error) {
    return <div className="campaign-monitor-redirect-container error">{error}</div>;
  }

  return null; // Render nothing if not loading or error
}

export default CampaignMonitorRedirect;
