// App.js
import React from 'react';
import './App.css';
import Home from './Home';
import FAQ from './FAQ';
import SalesforceRedirect from './SalesforceRedirect';
import NotFound from './NotFound';
import Navbar from './Navbar';
import UserConfigurations from './UserConfigurations';
import { UserAuthenticationDataProvider, useUserAuthenticationData } from './UserAuthenticationDataContext';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { SalesforceDataProvider } from './SalesforceDataContext';
import { AnymailfinderDataProvider } from './AnymailfinderDataContext';
import { CustomCompanyDataProvider } from './CustomCompanyDataContext';
import { DragDropDataProvider } from './DragDropDataContext';
import { LinkedInDataProvider } from './LinkedInDataContext'; // Import LinkedInDataProvider
import { OpenAIDataProvider } from './OpenAIDataContext';
import { GoogleDataProvider } from './GoogleDataContext';
import { CampaignMonitorDataProvider } from './CampaignMonitorDataContext';
import { CodeBuddyDataProvider } from './CodeBuddyDataContext';
import LinkedInRedirect from './LinkedInRedirect';
import CampaignMonitorRedirect from './CampaignMonitorRedirect';
import UserAuthenticationScreen from './UserAuthenticationScreen'; // Import UserAuthenticationScreen
import ContactProfilePage from './ContactProfilePage'; // Import the ContactProfilePage component
import LandingPage from './LandingPage'; // Import the new LandingPage component
import LandingNavbar from './LandingNavbar'; // Import the new LandingNavbar component
import Dashboard from './components/Dashboard/Dashboard'; // Import the Dashboard component
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AppProvider } from './context/AppContext';
import Pricing from './Pricing';
import { PricingProvider } from './PricingContext';
import BlogListPage from './components/Blog/BlogListPage';
import BlogPostPage from './components/Blog/BlogPostPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4671D5',
    },
    secondary: {
      main: '#4A90E2',
    },
    success: {
      main: '#32b632',
    },
    background: {
      default: '#f8f9fa',
    },
  },
  typography: {
    fontFamily: "'Montserrat', 'Open Sans', Raleway",
  },
});

const AppHeader = () => {
  const { isAuthenticated } = useUserAuthenticationData();

  return (
    <header className={`app-header ${isAuthenticated ? 'authenticated' : ''}`}>
      {isAuthenticated ? (
        <Navbar />
      ) : (
        <div className="app-header">
          <LandingNavbar />
        </div>
      )}
    </header>
  );
};

function ContentWithRoutes() {
  const { isAuthenticated, setIsAuthenticated } = useUserAuthenticationData();

  return (
    <Routes>
      <Route path="/" element={<AuthenticatedContent />} />
      <Route path="/login" element={<UserAuthenticationScreen />} />
      <Route path="/frequently-asked-questions" element={isAuthenticated ? <FAQ /> : <UserAuthenticationScreen />} />
      <Route path="/settings" element={isAuthenticated ? <UserConfigurations /> : <UserAuthenticationScreen />} />
      <Route path="/salesforce-callback" element={<SalesforceRedirect setIsAuthenticated={setIsAuthenticated} />} />
      <Route path="/linkedin-callback" element={<LinkedInRedirect setIsAuthenticated={setIsAuthenticated} />} />
      <Route path="/campaign-monitor-callback" element={<CampaignMonitorRedirect setIsAuthenticated={setIsAuthenticated} />} />
      <Route path="/contact/:salesforceContactID" element={<ContactProfilePage setIsAuthenticated={setIsAuthenticated} />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/blog" element={<BlogListPage />} />
      <Route path="/blog/:slug" element={<BlogPostPage />} />
      <Route path="*" element={isAuthenticated ? <NotFound /> : <UserAuthenticationScreen />} />
    </Routes>
  );
}




function App() {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>AgencyEdge - AI-Powered CRM Enrichment</title>
        <meta name="description" content="Agency Edge provides AI-powered CRM enrichment and email segmentation solutions for agencies." />
      </Helmet>
      <UserAuthenticationDataProvider>
        <AppProvider>
          <div className="App">
            <SalesforceDataProvider>
              <AnymailfinderDataProvider>
                <CustomCompanyDataProvider>
                  <LinkedInDataProvider>
                    <OpenAIDataProvider>
                      <GoogleDataProvider>
                        <CampaignMonitorDataProvider>
                          <CodeBuddyDataProvider>
                            <DragDropDataProvider>
                              <PricingProvider>
                                <Router>
                                  <div className="app-main">
                                    <AppHeader />
                                    <div className="content">
                                      <ContentWithRoutes />
                                    </div>
                                  </div>
                              </Router>
                              </PricingProvider>
                            </DragDropDataProvider>
                          </CodeBuddyDataProvider>
                        </CampaignMonitorDataProvider>
                      </GoogleDataProvider>
                    </OpenAIDataProvider>
                  </LinkedInDataProvider>
                </CustomCompanyDataProvider>
              </AnymailfinderDataProvider>
            </SalesforceDataProvider>
          </div>
        </AppProvider>
      </UserAuthenticationDataProvider>
    </ThemeProvider>
  );
}


const AuthenticatedContent = () => {
  const { isAuthenticated } = useUserAuthenticationData();

  console.log('AuthenticatedContent rendering, isAuthenticated:', isAuthenticated);
  
  //return isAuthenticated ? <Home /> : <LandingPage />; // Render LandingPage instead of UserAuthenticationScreen

  return isAuthenticated ? <Dashboard /> : <LandingPage />; // Render LandingPage instead of UserAuthenticationScreen
};

export default App;



//const AuthenticatedContent = () => {
 // const { isAuthenticated } = useUserAuthenticationData();

  //console.log('AuthenticatedContent rendering, isAuthenticated:', isAuthenticated);

  //return isAuthenticated ? <Home /> : <LandingPage />; // Render LandingPage instead of UserAuthenticationScreen
//};

//export default App;

