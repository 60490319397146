// ClientInfo.js

/**
 * Retrieves detailed information about the client's browser and system.
 * @returns {Object} An object containing various client information.
 */
export const getClientInfo = () => {
    const { userAgent } = navigator;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const colorDepth = window.screen.colorDepth;
    const pixelRatio = window.devicePixelRatio || 1;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const language = navigator.language || navigator.userLanguage;
  
    return {
      userAgent,
      screenResolution: `${screenWidth}x${screenHeight}`,
      colorDepth,
      pixelRatio,
      timezone,
      language,
      platform: navigator.platform,
      cookiesEnabled: navigator.cookieEnabled,
      doNotTrack: navigator.doNotTrack,
      onLine: navigator.onLine,
      connectionType: getConnectionType(),
      browser: getBrowserInfo(),
      os: getOSInfo(),
      device: getDeviceInfo(),
    };
  };
  
  /**
   * Determines the user's connection type if available.
   * @returns {Object} Connection information or null if not available.
   */
  const getConnectionType = () => {
    if ('connection' in navigator) {
      const { effectiveType, downlink, rtt, saveData } = navigator.connection;
      return {
        effectiveType,
        downlink,
        rtt,
        saveData,
      };
    }
    return null;
  };
  
  /**
   * Retrieves information about the user's browser.
   * @returns {Object} Browser information.
   */
  const getBrowserInfo = () => {
    const ua = navigator.userAgent;
    let browser = "Unknown";
    let version = "Unknown";
  
    if (ua.includes("Firefox/")) {
      browser = "Firefox";
      version = ua.split("Firefox/")[1];
    } else if (ua.includes("Chrome/")) {
      browser = "Chrome";
      version = ua.split("Chrome/")[1].split(" ")[0];
    } else if (ua.includes("Safari/") && !ua.includes("Chrome/")) {
      browser = "Safari";
      version = ua.split("Version/")[1].split(" ")[0];
    } else if (ua.includes("MSIE ")) {
      browser = "Internet Explorer";
      version = ua.split("MSIE ")[1].split(";")[0];
    } else if (ua.includes("Edg/")) {
      browser = "Edge";
      version = ua.split("Edg/")[1];
    }
  
    return { name: browser, version };
  };
  
  /**
   * Retrieves information about the user's operating system.
   * @returns {Object} OS information.
   */
  const getOSInfo = () => {
    const ua = navigator.userAgent;
    let os = "Unknown";
    let version = "Unknown";
  
    if (ua.includes("Win")) {
      os = "Windows";
      if (ua.includes("Windows NT 10.0")) version = "10";
      else if (ua.includes("Windows NT 6.3")) version = "8.1";
      else if (ua.includes("Windows NT 6.2")) version = "8";
      else if (ua.includes("Windows NT 6.1")) version = "7";
    } else if (ua.includes("Mac")) {
      os = "macOS";
      version = ua.split("Mac OS X ")[1].split(")")[0];
    } else if (ua.includes("Linux")) {
      os = "Linux";
    } else if (ua.includes("Android")) {
      os = "Android";
      version = ua.split("Android ")[1].split(";")[0];
    } else if (ua.includes("iOS")) {
      os = "iOS";
      version = ua.split("OS ")[1].split(" ")[0].replace(/_/g, ".");
    }
  
    return { name: os, version };
  };
  
  /**
   * Retrieves information about the user's device type.
   * @returns {string} Device type.
   */
  const getDeviceInfo = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "Tablet";
    }
    if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      return "Mobile";
    }
    return "Desktop";
  };
  
  /**
   * Retrieves the source of the current page visit.
   * @returns {Object} An object containing source information.
   */
  export const getSource = () => {
    const referrer = document.referrer;
    const currentPath = window.location.pathname;
    const fullUrl = window.location.href;
    const searchParams = new URLSearchParams(window.location.search);
    const utmSource = searchParams.get('utm_source');
    const utmMedium = searchParams.get('utm_medium');
    const utmCampaign = searchParams.get('utm_campaign');
    const utmTerm = searchParams.get('utm_term');
    const utmContent = searchParams.get('utm_content');
  
    return {
      referrer,
      currentPath,
      fullUrl,
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      utmContent,
    };
  };
  

/**
 * Fetches the user's IP address from an external service.
 * @returns {Promise<string>} A promise that resolves to the user's IP address.
 */
export const getIPAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Error fetching IP address:', error);
      return null;
    }
  };

/**
 * Retrieves information about the user's session.
 * @returns {Object} An object containing session information.
 */
export const getSessionInfo = () => {
    const now = Date.now();
    const sessionStart = localStorage.getItem('sessionStart') || now.toString();
    const pageViews = parseInt(localStorage.getItem('pageViews') || '0') + 1;
    const lastActivity = localStorage.getItem('lastActivity') || now.toString();
    
    localStorage.setItem('sessionStart', sessionStart);
    localStorage.setItem('pageViews', pageViews.toString());
    localStorage.setItem('lastActivity', now.toString());
  
    return {
      sessionStart: new Date(parseInt(sessionStart)).toISOString(),
      pageViews,
      timeOnSite: now - parseInt(sessionStart),
      timeSinceLastActivity: now - parseInt(lastActivity),
    };
  };
  
  /**
   * Tracks user interaction events.
   * @param {string} eventName - The name of the event to track.
   * @param {Object} eventData - Additional data associated with the event.
   */
export const trackEvent = async (eventName, eventData = {}) => {
  const timestamp = new Date().toISOString();
  const sessionId = localStorage.getItem('sessionId');
  const clientInfo = getClientInfo();
  const sourceInfo = getSource();
  const sessionInfo = getSessionInfo();

  const event = {
    sessionId,
    eventName,
    timestamp,
    clientInfo,
    sourceInfo,
    sessionInfo,
    ...eventData,
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/track-event`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(event),
    });

    if (!response.ok) {
      throw new Error('Failed to track event');
    }

    console.log('Event tracked:', event);
  } catch (error) {
    console.error('Error tracking event:', error);
    // Fallback to localStorage if API call fails
    const events = JSON.parse(localStorage.getItem('trackedEvents') || '[]');
    events.push(event);
    localStorage.setItem('trackedEvents', JSON.stringify(events));
  }
};
  
  /**
   * Retrieves all tracked events for the current session.
   * @returns {Array} An array of tracked events.
   */
  export const getTrackedEvents = () => {
    return JSON.parse(localStorage.getItem('trackedEvents') || '[]');
  };
  
  /**
   * Clears all tracked events from localStorage.
   */
  export const clearTrackedEvents = () => {
    localStorage.removeItem('trackedEvents');
  };