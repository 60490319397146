// src/components/Dashboard/EnrichedCompanies/EnrichedCompanyClients.js
import React, { useState, useMemo } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Typography, 
  Collapse, 
  IconButton, 
  Box,
  Chip,
  Link
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LaunchIcon from '@mui/icons-material/Launch';
import '../../../styles/EnrichedCompanies/EnrichedCompanyClients.css';

const ClientRow = ({ client }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow 
        onClick={() => setOpen(!open)} 
        className={`client-row ${open ? 'expanded' : ''}`}
        hover
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{client.ClientCompanyName}</TableCell>
        <TableCell>{client.SourceYear}</TableCell>
        <TableCell>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {client.SourceCategories.map((category, index) => (
              <Chip
                key={index}
                label={category}
                size="small"
                className="category-chip"
              />
            ))}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className="client-details">
              <Typography variant="h6" gutterBottom component="div">
                {client.SourceName}
              </Typography>
              <Typography variant="body1" paragraph>
                {client.SourceSummary}
              </Typography>
              {client.SourceURL && (
                <Link
                  href={client.SourceURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="source-link"
                >
                  View Source <LaunchIcon fontSize="small" />
                </Link>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const EnrichedCompanyClients = ({ data }) => {
  const clientsCount = useMemo(() => data.length, [data]);

  return (
    <Paper className="enriched-company-clients">
      <Box display="flex" alignItems="center" mb={2} p={2}>
        <Chip 
          label={`${clientsCount} Clients`} 
          color="primary" 
          className="count-chip"
        />
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow className="table-header">
              <TableCell />
              <TableCell>Client</TableCell>
              <TableCell>Year</TableCell>
              <TableCell>Categories</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((client, index) => (
              <ClientRow key={index} client={client} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default EnrichedCompanyClients;