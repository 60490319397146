//TargetLeadClients.js
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import '../../../styles/Targets/TargetLeadClients.css';

const TargetLeadClients = ({ data }) => {
  if (!data || data.length === 0) {
    return <Typography>No lead title data available.</Typography>;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Client Company Name</TableCell>
            <TableCell>Lead Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.ClientCompanyName}</TableCell>
              <TableCell>{item.LeadCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TargetLeadClients;