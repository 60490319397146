// ContactProfilePage.js
import React from 'react';
import { Container, IconButton, Paper } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ContactProfileHeader from './ContactProfileHeader';
import ContactProfileDescription from './ContactProfileDescription';
import CampaignMonitorStatistics from './ContactProfileCampaignMonitorHub';
import ContactProfileFields from './ContactProfileFields';
import './ProfilePage.css';

const ContactProfilePage = ({ codeBuddyData, salesforceContactID, onBack }) => {
  const contact = codeBuddyData.find(c => c.SalesforceContactID === salesforceContactID);

  if (!contact) {
    return (
      <Container className="contact-profile">
        <div>No CodeBuddy contact data available.</div>
        <IconButton onClick={onBack} className="back-btn" aria-label="go back">
          <ArrowBackIosNewIcon />
        </IconButton>
      </Container>
    );
  }

  return (
    <Container className="contact-profile">
      <IconButton onClick={onBack} className="back-btn" aria-label="go back">
        <ArrowBackIosNewIcon />
      </IconButton>
      <Paper elevation={3} className="contact-details">
        <ContactProfileHeader contact={contact} />
        <ContactProfileDescription contact={contact} /> {/* Corrected prop name */}
        <ContactProfileFields contact={contact} />
        <CampaignMonitorStatistics contact={contact} />
      </Paper>
    </Container>
  );
};

export default ContactProfilePage;

