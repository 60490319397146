// src/context/AppContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [companies, setCompanies] = useState({ 
    stats: null, 
    list: [], 
    totalCompanies: 0, 
    currentPage: 1, 
    totalPages: 1 
  });
  const [contacts, setContacts] = useState({ 
    stats: null, 
    list: [], 
    totalContacts: 0, 
    currentPage: 1, 
    totalPages: 1 
  });
  const [subscribers, setSubscribers] = useState({ 
    stats: null, 
    list: [], 
    totalSubscribers: 0, 
    currentPage: 1, 
    totalPages: 1 
  });
  const [isLoading, setIsLoading] = useState(true);

  const [enrichedCompanies, setEnrichedCompanies] = useState({ 
    stats: null,
    list: [], 
    totalCompanies: 0, 
    currentPage: 1, 
    totalPages: 1 
  });

  const [enrichedContacts, setEnrichedContacts] = useState({ total: 0, new: 0 });
  const [targets, setTargets] = useState({ total: 0, newLeads: 0 });

  const [targetsList, setTargetsList] = useState({
    list: [],
    totalTargets: 0
  });


  const fetchData = async () => {
    setIsLoading(true);
    try {
      const userId = localStorage.getItem('userId');
      if (!userId) throw new Error('User ID is not available');
  
      const headers = { 'UserId': userId };
  
      const [companyStats, contactStats, companiesList, contactsList, enrichedCompanyStats, enrichedContactStats, targetStats, subscriberStats, subscribersList, targetsData, enrichedCompaniesList] = await Promise.all([
        fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_company_stats`, { headers }).then(res => res.json()),
        fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_contact_stats`, { headers }).then(res => res.json()),
        fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_companies?page=1`, { headers }).then(res => res.json()),
        fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_contacts?page=1`, { headers }).then(res => res.json()),
        fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_enriched_company_stats`, { headers }).then(res => res.json()),
        fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_enriched_contact_stats`, { headers }).then(res => res.json()),
        fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_target_stats`, { headers }).then(res => res.json()),
        fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_subscriber_stats`, { headers }).then(res => res.json()),
        fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_subscribers?page=1`, { headers }).then(res => res.json()),
        fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_targets`, { headers }).then(res => res.json()),
        fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_enriched_companies?page=1`, { headers }).then(res => res.json())
      ]);

      setCompanies({ 
        stats: companyStats, 
        list: companiesList.companies, 
        totalCompanies: companiesList.totalCompanies,
        currentPage: companiesList.currentPage,
        totalPages: companiesList.totalPages
      });
      setContacts({ 
        stats: contactStats, 
        list: contactsList.contacts,
        totalContacts: contactsList.totalContacts,
        currentPage: contactsList.currentPage,
        totalPages: contactsList.totalPages
      });
      setSubscribers({ 
        stats: {
          active: {
            total: subscriberStats.activeSubscribers,
            new: subscriberStats.newActiveSubscribers
          },
          bounced: {
            total: subscriberStats.bouncedSubscribers,
            new: subscriberStats.newBouncedSubscribers
          },
          unsubscribed: {
            total: subscriberStats.unsubscribedSubscribers,
            new: subscriberStats.newUnsubscribedSubscribers
          }
        }, 
        list: subscribersList.subscribers,
        totalSubscribers: subscribersList.totalSubscribers,
        currentPage: subscribersList.currentPage,
        totalPages: subscribersList.totalPages
      });
   
      setEnrichedCompanies({
        stats: enrichedCompanyStats, 
        list: enrichedCompaniesList.companies, 
        totalCompanies: enrichedCompaniesList.totalCompanies,
        currentPage: enrichedCompaniesList.currentPage,
        totalPages: enrichedCompaniesList.totalPages
      });

      setEnrichedContacts({
        total: enrichedContactStats.totalEnrichedContacts,
        new: enrichedContactStats.newEnrichedContacts
      });

      setTargets({
        total: targetStats.totalTargets,
        newLeads: targetStats.newTargetLeads
      });

      setTargetsList({
        list: targetsData.targets,
        totalTargets: targetsData.totalTargets
      });

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchMoreCompanies = async (page) => {
    try {
      const userId = localStorage.getItem('userId');
      if (!userId) throw new Error('User ID is not available');

      const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_companies?page=${page}`, { headers: { 'UserId': userId } });
      const data = await response.json();

      setCompanies(prev => ({
        ...prev,
        list: [...prev.list, ...data.companies],
        currentPage: data.currentPage,
        totalPages: data.totalPages
      }));
    } catch (error) {
      console.error('Error fetching more companies:', error);
    }
  };


  const fetchMoreContacts = async (page) => {
    try {
      const userId = localStorage.getItem('userId');
      if (!userId) throw new Error('User ID is not available');

      const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_contacts?page=${page}`, { headers: { 'UserId': userId } });
      const data = await response.json();

      setContacts(prev => ({
        ...prev,
        list: [...prev.list, ...data.contacts],
        currentPage: data.currentPage,
        totalPages: data.totalPages
      }));
    } catch (error) {
      console.error('Error fetching more contacts:', error);
    }
  };

  const fetchMoreEnrichedCompanies = async (page) => {
    try {
      const userId = localStorage.getItem('userId');
      if (!userId) throw new Error('User ID is not available');
  
      const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/get_enriched_companies?page=${page}`, { headers: { 'UserId': userId } });
      const data = await response.json();
  
      setEnrichedCompanies(prev => ({
        ...prev,
        list: [...prev.list, ...data.companies],
        currentPage: data.currentPage,
        totalPages: data.totalPages
      }));
    } catch (error) {
      console.error('Error fetching more enriched companies:', error);
    }
  };

  const fetchMoreSubscribers = async (page) => {
    try {
      const userId = localStorage.getItem('userId');
      if (!userId) throw new Error('User ID is not available');

      const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_subscribers?page=${page}`, { headers: { 'UserId': userId } });
      const data = await response.json();

      setSubscribers(prev => ({
        ...prev,
        list: [...prev.list, ...data.subscribers],
        currentPage: data.currentPage,
        totalPages: data.totalPages
      }));
    } catch (error) {
      console.error('Error fetching more subscribers:', error);
    }
  };

  

  const fetchTargetDetails = async (targetId) => {
    try {
      const userId = localStorage.getItem('userId');
      if (!userId) throw new Error('User ID is not available');
  
      const headers = { 'UserId': userId };
      const baseUrl = process.env.REACT_APP_API_GATEWAY_URL;

      const endpoints = [
        `${baseUrl}/salesforce/target/summary/${targetId}`,
        `${baseUrl}/salesforce/target/categories/${targetId}`,
        `${baseUrl}/salesforce/target/clients/${targetId}`,
        `${baseUrl}/salesforce/target/campaigns/${targetId}`,
        `${baseUrl}/salesforce/target/leads/titles/${targetId}`,
        `${baseUrl}/salesforce/target/leads/locations/${targetId}`,
        `${baseUrl}/salesforce/target/leads/clients/${targetId}`,
        `${baseUrl}/salesforce/target/leads/categories/${targetId}`,
        `${baseUrl}/salesforce/target/leads/types/${targetId}`
      ];

      const responses = await Promise.all(endpoints.map(endpoint => 
        fetch(endpoint, { headers }).then(res => res.json())
      ));

      return {
        summary: responses[0],
        categories: responses[1],
        clients: responses[2],
        campaigns: responses[3],
        leadTitles: responses[4],
        leadLocations: responses[5],
        leadClients: responses[6],
        leadCategories: responses[7],
        leadTypes: responses[8]
      };
    } catch (error) {
      console.error('Error fetching target details:', error);
      throw error;
    }
  };

  const fetchEnrichedCompanyDetails = async (salesforceAccountId) => {
    try {
      const userId = localStorage.getItem('userId');
      if (!userId) throw new Error('User ID is not available');
  
      const headers = { 'UserId': userId };
      const baseUrl = process.env.REACT_APP_API_GATEWAY_URL;

      const endpoints = [
        `${baseUrl}/salesforce/enriched_companies/summary/${salesforceAccountId}`,
        `${baseUrl}/salesforce/enriched_companies/categories/${salesforceAccountId}`,
        `${baseUrl}/salesforce/enriched_companies/clients/${salesforceAccountId}`,
        `${baseUrl}/salesforce/enriched_companies/contacts/${salesforceAccountId}`,
        `${baseUrl}/salesforce/enriched_companies/sync_logs/${salesforceAccountId}`,
        `${baseUrl}/salesforce/enriched_companies/targets/${salesforceAccountId}`
      ];

      const responses = await Promise.all(endpoints.map(endpoint => 
        fetch(endpoint, { headers }).then(res => res.json())
      ));

      return {
        summary: responses[0],
        categories: responses[1],
        clients: responses[2],
        contacts: responses[3],
        syncLogs: responses[4],
        targets: responses[5]
      };
    } catch (error) {
      console.error('Error fetching enriched company details:', error);
      throw error;
    }
  };



  useEffect(() => {
    fetchData();
  }, []);


  const fetchTargetLeadsByTitle = async (targetId, title) => {
    try {
      const userId = localStorage.getItem('userId');
      if (!userId) throw new Error('User ID is not available');

      const headers = { 'UserId': userId };
      const baseUrl = process.env.REACT_APP_API_GATEWAY_URL;
      const projectId = 1070; // Replace with actual project ID or fetch it dynamically

      const response = await fetch(`${baseUrl}/salesforce/target/leads/${targetId}/${encodeURIComponent(title)}?project_id=${projectId}`, { headers });
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching target leads by title:', error);
      throw error;
    }
  };

  const fetchTargetLeadsByLocation = async (targetId, location) => {
    try {
      const userId = localStorage.getItem('userId');
      if (!userId) throw new Error('User ID is not available');

      const headers = { 'UserId': userId };
      const baseUrl = process.env.REACT_APP_API_GATEWAY_URL;
      const projectId = 1070; // Replace with actual project ID or fetch it dynamically

      const response = await fetch(`${baseUrl}/salesforce/target/leads/${targetId}/${encodeURIComponent(location)}?project_id=${projectId}`, { headers });
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching target leads by location:', error);
      throw error;
    }
  };

  return (
    <AppContext.Provider value={{ 
      companies, 
      contacts, 
      subscribers,
      enrichedCompanies,
      enrichedContacts,
      targets,
      isLoading, 
      fetchData, 
      fetchMoreCompanies, 
      fetchMoreContacts,
      fetchMoreSubscribers,
      targetsList,
      fetchTargetDetails,
      fetchTargetLeadsByTitle,
      fetchTargetLeadsByLocation,
      fetchMoreEnrichedCompanies,
      fetchEnrichedCompanyDetails
    }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);