// src/components/Dashboard/EnrichedCompanies/EnrichedCompanyCategories.js
import React, { useMemo } from 'react';
import { Chip, Paper, Box } from '@mui/material';
import '../../../styles/EnrichedCompanies/EnrichedCompanyCategories.css';

const EnrichedCompanyCategories = ({ data }) => {
  const categoriesCount = useMemo(() => data.length, [data]);

  if (!data || data.length === 0) {
    return <Paper className="enriched-company-categories">No categories available.</Paper>;
  }

  return (
    <Paper className="enriched-company-categories">
      <Box display="flex" alignItems="center" mb={2} p={2}>
        <Chip 
          label={`${categoriesCount} Categories`} 
          color="primary" 
          className="count-chip"
        />
      </Box>
      <Box p={2}>
        {data.map((category, index) => (
          <Chip key={index} label={category} className="category-chip" />
        ))}
      </Box>
    </Paper>
  );
};

export default EnrichedCompanyCategories;