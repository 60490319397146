// src/components/Dashboard/Dashboard.js
import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import MainContent from './MainContent';
import { useAppContext } from '../../context/AppContext';
import '../../styles/Dashboard.css';

const Dashboard = () => {
  const [currentSection, setCurrentSection] = useState('Overview');
  const { isLoading } = useAppContext();

  if (isLoading) {
    return <div>Loading dashboard data...</div>;
  }

  return (
    <div className="dashboard">
      <Sidebar setCurrentSection={setCurrentSection} />
      <MainContent currentSection={currentSection} />
    </div>
  );
};

export default Dashboard;

