// UserCompanyLogo.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import './UserCompanyLogo.css';

const UserCompanyLogo = () => {
  return (
    <Link to="/" className="logo-link">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img
          src="ae_logo_white.png"
          alt="Agency Edge Logo"
          className="user-company-logo"
        />
        <Typography 
          variant="h6" 
          component="div"
          sx={{ 
            marginLeft: 2, 
            color: 'white', 
            fontWeight: 'bold' 
          }}
        >
          Agency Edge
        </Typography>
      </Box>
    </Link>
  );
};

export default UserCompanyLogo;