//CTASection.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import LandingDemoButton from './LandingDemoButton';

const CTASection = () => {
  return (
    <section className="cta-section">
      <Container maxWidth="lg">
        <Typography variant="h3" align="center" gutterBottom className="section-cta">
          Want to Leverage AI in Your CRM?
        </Typography>
        <Box textAlign="center">
          <LandingDemoButton />
        </Box>
      </Container>
    </section>
  );
};

export default CTASection;