import React, { useState } from 'react';
import { Tabs, Tab, Box, Paper } from '@mui/material';
import EnrichedCompanySyncLogs from './EnrichedCompanySyncLogs';
import EnrichedCompanyContacts from './EnrichedCompanyContacts';
import EnrichedCompanyClients from './EnrichedCompanyClients';
import EnrichedCompanyCategories from './EnrichedCompanyCategories';
import '../../../styles/EnrichedCompanies/EnrichedCompanyMainDisplay.css';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`enriched-company-tabpanel-${index}`}
      aria-labelledby={`enriched-company-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const EnrichedCompanyMainDisplay = ({ data }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className="enriched-company-main-display">
      <Tabs 
        value={value} 
        onChange={handleChange} 
        aria-label="Enriched company display tabs"
        variant="fullWidth"
      >
        <Tab label="Contacts" id="enriched-company-tab-0" aria-controls="enriched-company-tabpanel-0" />
        <Tab label="Clients" id="enriched-company-tab-1" aria-controls="enriched-company-tabpanel-1" />
        <Tab label="Categories" id="enriched-company-tab-2" aria-controls="enriched-company-tabpanel-2" />
        <Tab label="Recent Activity" id="enriched-company-tab-3" aria-controls="enriched-company-tabpanel-3" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <EnrichedCompanyContacts data={data.contacts} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EnrichedCompanyClients data={data.clients} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EnrichedCompanyCategories data={data.categories} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <EnrichedCompanySyncLogs data={data.syncLogs} />
      </TabPanel>
    </Paper>
  );
};

export default EnrichedCompanyMainDisplay;