//EnrichedContactsTile.js
import React from 'react';
import SummaryTile from './SummaryTile';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import '../../../styles/tiles/EnrichedContactsTile.css';

const EnrichedContactsTile = ({ enrichedContacts, enrichmentPercentage, onClick }) => {
  const subContent = `${enrichmentPercentage}% enriched`;
  return (
    <SummaryTile
      title="Enriched Contacts"
      mainMetric={enrichedContacts}
      subContent={subContent}
      icon={<VerifiedUserIcon className="summary-tile-icon" />}
      onClick={onClick}
    />
  );
};

export default EnrichedContactsTile;


