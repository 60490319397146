// UserAuthenticationLogin.js
import React, { useState, useContext, useEffect } from 'react';
import { UserAuthenticationDataContext } from './UserAuthenticationDataContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import './UserAuthentication.css';
import { Typography } from '@mui/material';
import { useCodeBuddyData } from './CodeBuddyDataContext';
import './ServiceInfo.css';


const UserAuthenticationLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setIsAuthenticated, setUserFirstName } = useContext(UserAuthenticationDataContext);
  const { codeBuddyData, codeBuddySelectedData } = useCodeBuddyData();

  useEffect(() => {
    const handleEnterKey = (event) => {
      if (event.key === 'Enter') {
        handleLogin();
      }
    };

    document.addEventListener('keydown', handleEnterKey);

    return () => {
      document.removeEventListener('keydown', handleEnterKey);
    };
  }, [email, password, loading]); // Dependency array ensures the effect is updated with the latest state

  const handleLogin = async () => {
    console.log('Login attempt started');
    if (loading) {
      console.log('Login is already in progress');
      return; // Prevent multiple submissions if already loading
    }
    
    setLoading(true);
    setError('');
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/user_authentication/login`, { email, password });
      console.log('Login response received:', response.data);
      if (response.data.success) {
        console.log('Login successful');
        setIsAuthenticated(true);
        localStorage.removeItem('salesforce_access_token');
        localStorage.removeItem('salesforce_instance_url');
        localStorage.removeItem('salesforceIsConnected');
        localStorage.removeItem('pkce_code_verifier');
        localStorage.removeItem('campaign_monitor_access_token');
        localStorage.removeItem('campaign_monitor_auth_state');
        localStorage.removeItem('campaignMonitorIsConnected');
        localStorage.removeItem('linkedin_access_token');
        localStorage.removeItem('linkedInIsConnected');
        localStorage.removeItem('linkedin_auth_state');

        const userId = response.data.userId;


        setUserFirstName(response.data.firstName); // Update the context state
        localStorage.setItem('userFirstName', response.data.firstName); // Store user first name in localStorage
        localStorage.setItem('userId', userId); // Store user ID in localStorage


        navigate('/'); // Log the navigation attempt
        console.log('Navigating to Home component after successful login');
      } else {
        console.error("Login failed: Invalid credentials");
        setError("Invalid credentials");
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Login error: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const textColorClass = codeBuddySelectedData 
  ? 'data-finished-text' 
  : codeBuddyData 
  ? 'data-active-text' 
  : 'data-inactive-text';

  return (
    <div className="login-container">
      <div className="service-login-header">
          <img src="ae_logo_blue.png" alt="Agency Edge Logo" className="service-logo" />
          <Typography variant="h6" className={`service-title ${textColorClass}`}>Agency Edge</Typography>
      </div>
      <div className="input-wrapper email-input-wrapper">
        <input 
          type="email" 
          placeholder="Email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          className="login-input"
          disabled={loading}
        />
      </div>
      <div className="input-wrapper password-input-wrapper">
        <input 
          type={showPassword ? 'text' : 'password'} 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          className="login-input password-login-input"
          disabled={loading}
        />
        <div className="visibility-icon-wrapper">
          <Tooltip title={showPassword ? "Hide Password" : "Show Password"}>
            <IconButton 
              onClick={() => setShowPassword(!showPassword)} 
              disabled={loading}
              className="visibility-toggle-button"
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <button onClick={handleLogin} className="login-button" disabled={loading}>
        {loading ? <CircularProgress size={24} color="inherit" className="circular-progress" /> : 'Login'}
      </button>
      {error && <div className="login-error">{error}</div>}
    </div>
  );
};

export default UserAuthenticationLogin;



