//TargetsTile.js
import React from 'react';
import SummaryTile from './SummaryTile';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import '../../../styles/tiles/TargetsTile.css';

const TargetsTile = ({ totalTargets, newTargets, onClick }) => {
  const subContent = `+${newTargets} segments created`;
  return (
    <SummaryTile
      title="Targets"
      mainMetric={totalTargets}
      subContent={subContent}
      icon={<GpsFixedIcon className="summary-tile-icon" />}
      onClick={onClick}
    />
  );
};

export default TargetsTile;