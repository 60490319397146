//PricingContext.js
import React, { createContext, useContext, useState } from 'react';

const PricingContext = createContext();

export const usePricing = () => useContext(PricingContext);

export const PricingProvider = ({ children }) => {
  const [crmSize, setCrmSize] = useState(1000);
  const [selectedPlan, setSelectedPlan] = useState('base');
  const [billingCycle, setBillingCycle] = useState('monthly');

  const value = {
    crmSize,
    setCrmSize,
    selectedPlan,
    setSelectedPlan,
    billingCycle,
    setBillingCycle,
  };

  return (
    <PricingContext.Provider value={value}>
      {children}
    </PricingContext.Provider>
  );
};