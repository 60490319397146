// src/components/Dashboard/Targets/TargetProfile.js
import React, { useState, useEffect } from 'react';
import { CircularProgress, Button } from '@mui/material';
import TargetSummary from './TargetSummary';
import TargetCampaigns from './TargetCampaigns';
import TargetClients from './TargetClients';
import TargetCategories from './TargetCategories';
import TargetLeadDisplay from './TargetLeadDisplay';
import '../../../styles/Targets/TargetProfile.css';

const TargetProfile = ({ targetId, onBack }) => {
  const [targetData, setTargetData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTargetData = async () => {
      setLoading(true);
      setError(null);
      try {
        const userId = localStorage.getItem('userId');
        if (!userId) throw new Error('User ID is not available');
    
        const headers = { 'UserId': userId };
        const baseUrl = process.env.REACT_APP_API_GATEWAY_URL;

        const endpoints = [
          `${baseUrl}/salesforce/target/summary/${targetId}`,
          `${baseUrl}/salesforce/target/categories/${targetId}`,
          `${baseUrl}/salesforce/target/clients/${targetId}`,
          `${baseUrl}/salesforce/target/campaigns/${targetId}`,
          `${baseUrl}/salesforce/target/leads/titles/${targetId}`,
          `${baseUrl}/salesforce/target/leads/locations/${targetId}`,
          `${baseUrl}/salesforce/target/leads/clients/${targetId}`,
          `${baseUrl}/salesforce/target/leads/categories/${targetId}`,
          `${baseUrl}/salesforce/target/leads/types/${targetId}`
        ];

        const responses = await Promise.all(endpoints.map(endpoint => 
          fetch(endpoint, { headers }).then(async res => {
            if (!res.ok) {
              const errorData = await res.json();
              throw new Error(errorData.message || 'An error occurred');
            }
            return res.json();
          })
        ));

        setTargetData({
          summary: responses[0] || {},
          categories: responses[1] || [],
          clients: responses[2] || [],
          campaigns: responses[3] || [],
          leadTitles: responses[4] || [],
          leadLocations: responses[5] || [],
          leadClients: responses[6] || [],
          leadCategories: responses[7] || [],
          leadTypes: responses[8] || []
        });
      } catch (error) {
        console.error('Error fetching target data:', error);
        setError('Failed to load target data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchTargetData();
  }, [targetId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="target-profile">
      <Button onClick={onBack} variant="contained" color="primary" style={{ marginBottom: '20px' }}>
        Back to Targets List
      </Button>
      {targetData.summary && targetData.leadTypes && (
        <TargetSummary data={targetData.summary} leadTypes={targetData.leadTypes} />
      )}
      {targetData.campaigns && <TargetCampaigns data={targetData.campaigns} />}
      {targetData.clients && <TargetClients data={targetData.clients} />}
      {targetData.categories && <TargetCategories data={targetData.categories} />}
      <TargetLeadDisplay data={targetData} targetId={targetId} />
    </div>
  );
};

export default TargetProfile;