//HeroStats.js
import React from 'react';
import { Container, Grid, Typography, Box, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import CountUp from 'react-countup';

const HeroStats = () => {
  const theme = useTheme();

  const heroStats = [
    { stat: 500, symbol: '%', description: 'Increased email clicks' },
    { stat: 200, symbol: '%', description: 'More high-value leads' },
    { stat: 90, symbol: '%', description: 'All data fields filed' },
    { stat: 30, symbol: '%', description: 'Fewer bounced emails' },
  ];

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4} justifyContent="center">
        {heroStats.map((item, index) => (
          <Grid item xs={8} sm={4} md={3} key={index}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Box
                className="stat-box"
                sx={{
                  padding: '24px 16px',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                  }
                }}
              >
                <Typography 
                  variant="h3" 
                  className="stat-number"
                  sx={{ 
                    color: theme.palette.primary.main, 
                    fontWeight: 700, 
                    marginBottom: '8px',
                    display: 'flex',
                    alignItems: 'baseline'
                  }}
                >
                  <CountUp end={item.stat} duration={2.5} />
                  <span style={{ fontSize: '0.7em', marginLeft: '2px' }}>{item.symbol}</span>
                </Typography>
                <Typography 
                  variant="body1" 
                  className="stat-description"
                  sx={{ 
                    fontWeight: 500, 
                    color: theme.palette.text.secondary,
                    lineHeight: 1.4
                  }}
                >
                  {item.description}
                </Typography>
              </Box>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default HeroStats;