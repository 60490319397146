// LinkedInDataContext.js
import React, { createContext, useState, useContext } from 'react';

const LinkedInDataContext = createContext(null);

export const useLinkedInData = () => useContext(LinkedInDataContext);

export const LinkedInDataProvider = ({ children }) => {
  const [linkedInData, setLinkedInData] = useState(null);
  const [linkedInStatistics, setLinkedInStatistics] = useState(null);
  const [linkedInProcessFinished, setLinkedInProcessFinished] = useState(false);
  const [linkedInProcessReady, setLinkedInProcessReady] = useState(false); // New state
  const [isLinkedInProcessing, setIsLinkedInProcessing] = useState(false);
  const [linkedInSalesforceData, setLinkedInSalesforceData] = useState([]); // New state
  const [selectedLinkedInRows, setSelectedLinkedInRows] = useState(new Set());
  const [linkedInNewCompanies, setLinkedInNewCompanies] = useState([]); // New state
  const [linkedInServiceInfo, setLinkedInServiceInfo] = useState(null); // New state
  const [linkedInIsConnected, setLinkedInIsConnected] = useState(false); // New state

  return (
    <LinkedInDataContext.Provider value={{ linkedInData, setLinkedInData, linkedInProcessReady, setLinkedInProcessReady, isLinkedInProcessing, setIsLinkedInProcessing, linkedInSalesforceData, setLinkedInSalesforceData, selectedLinkedInRows, setSelectedLinkedInRows, linkedInStatistics, setLinkedInStatistics, linkedInProcessFinished, setLinkedInProcessFinished, linkedInNewCompanies, setLinkedInNewCompanies, linkedInServiceInfo, setLinkedInServiceInfo, linkedInIsConnected, setLinkedInIsConnected }}>
      {children}
    </LinkedInDataContext.Provider>
  );
};
