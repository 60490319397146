// AnymailfinderServiceFooter.js
import React from 'react';
import { Paper, Tooltip, Typography } from '@mui/material';
import { useAnymailfinderData } from './AnymailfinderDataContext';
import './ServiceFooter.css';

function AnymailfinderServiceFooter() {
  const { anymailfinderStatistics, anymailfinderData } = useAnymailfinderData();

  const calculateEnrichmentPercentage = () => {
    const totalProcessedContacts = anymailfinderStatistics?.TotalProcessedEmails || 0;
    const totalContactsEnriched = anymailfinderStatistics?.ProcessedNewEmailsFound || 0;
    return totalProcessedContacts > 0 ? ((totalContactsEnriched / totalProcessedContacts) * 100).toFixed(1) : 0;
  };

  return (
    <div className="service-footer">
      {anymailfinderData && (
        <>
          <Paper className="footer-detail-card" elevation={1}>
            <Tooltip title="Total Contacts Synced">
              <Typography variant="h6">{anymailfinderStatistics ? anymailfinderStatistics.TotalProcessedEmails || 0 : 0}</Typography>
            </Tooltip>
            <Typography variant="caption">Contacts Searched</Typography>
          </Paper>

          <Paper className="footer-detail-card" elevation={1}>
            <Tooltip title="Total Contacts Updated">
              <Typography variant="h6">{anymailfinderStatistics ? anymailfinderStatistics.ProcessedNewEmailsFound || 0 : 0}</Typography>
            </Tooltip>
            <Typography variant="caption">New Emails Found</Typography>
          </Paper>

          <Paper className="footer-detail-card" elevation={1}>
            <Tooltip title="Updated Success Rate">
              <Typography variant="h6">{calculateEnrichmentPercentage()}%</Typography>
            </Tooltip>
            <Typography variant="caption">Success Rate</Typography>
          </Paper>
        </>
      )}
    </div>
  );
}

export default AnymailfinderServiceFooter;