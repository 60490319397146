//IntegratedWith.js
import React from 'react';
import { Box, Grid, Tooltip, Zoom } from '@mui/material';
import { motion } from 'framer-motion';

const integrations = [
  { name: 'Salesforce', logo: '/salesforce_logo.png' },
  { name: 'Campaign Monitor', logo: '/campaign_monitor_logo.png' },
  { name: 'LinkedIn', logo: '/linkedin_logo.png' },
  { name: 'OpenAI', logo: '/openai_logo_2.png' },
  { name: 'Google', logo: '/google_logo_2.png' },
  { name: 'Anymail finder', logo: '/anymailfinder_logo.png' },
];

const IntegratedWith = () => {
  return (
    <Box component="section" className="integrated-with">
      <Grid container spacing={0} justifyContent="center" alignItems="center">
        {integrations.map((integration, index) => (
          <Grid item xs={4} sm={3} md={2} key={index}>
            <Tooltip title={`${integration.name}`} TransitionComponent={Zoom}>
              <Box className="integration-logo-container">
                <motion.img
                  src={integration.logo}
                  alt={`${integration.name} logo`}
                  className="integration-logo"
                  whileHover={{ scale: 1.1 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                />
              </Box>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default IntegratedWith;