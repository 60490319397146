//LandingFooter.js
import React from 'react';
import { Container, Grid, Typography, Link } from '@mui/material';

const LandingFooter = () => {
  return (
    <footer className="landing-footer">
      <Container maxWidth="lg">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="body2">© 2024 Agency Edge. All rights reserved.</Typography>
          </Grid>
          <Grid item>
            <Link href="mailto:info@codebuddy.com" color="inherit">
              <Typography variant="body2">info@agencyedge.ai</Typography>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default LandingFooter;