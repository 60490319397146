// ContactProfileDescription.js
import React from 'react';
import { Typography, Link } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch'; // Icon for external links
import './ProfilePage.css';

const ContactProfileDescription = ({ contact }) => {
    const summary = contact.EnrichedCodeBuddySummary;

    // Function to return placeholder if summary is not available
    if (!summary || summary === 'Unknown') {
      return <Typography className="full-summary">No summary available</Typography>;
    }


  const parseSummaryWithLinks = (summary) => {
    const regex = /\[(https?:\/\/[^\]]+)\]/g; // Regex to find URLs within brackets
    const parts = summary.split(regex); // Split summary into parts by URLs

    return parts.map((part, index) => {
      // Check if part is a URL
      if (part.match(/^https?:\/\//)) {
        return (
          <Link key={index} href={part} target="_blank" rel="noopener noreferrer" className="summary-link">
            <LaunchIcon className="link-icon" />
          </Link>
        );
      }
      return part; // Return text part as is
    });
  };

  return (
    <Typography className="full-summary">
      {parseSummaryWithLinks(summary)}
    </Typography>
  );
};

export default ContactProfileDescription;
