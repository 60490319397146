// src/components/Dashboard/EnrichedCompanies/EnrichedCompanyDonutChart.js
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import '../../../styles/EnrichedCompanies/EnrichedCompanyDonutChart.css';

ChartJS.register(ArcElement, Tooltip);

const EnrichedCompanyDonutChart = ({ data }) => {
  const totalLeadCount = data.reduce((sum, item) => sum + item.LeadCount, 0);

  const chartData = {
    labels: data.map(item => item.TargetName),
    datasets: [
      {
        data: data.map(item => item.LeadCount),
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
        ],
        hoverBackgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
        ]
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.parsed || 0;
            const percentage = ((value / totalLeadCount) * 100).toFixed(1);
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
    cutout: '70%',
    maintainAspectRatio: false,
  };

  const plugins = [
    {
      id: 'centerText',
      beforeDraw: (chart) => {
        const { width, height, ctx } = chart;
        ctx.restore();
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';

        const centerX = width / 2;
        const centerY = height / 2;

        ctx.font = 'bold 24px Arial';
        ctx.fillStyle = '#000';
        ctx.fillText(totalLeadCount.toString(), centerX, centerY - 10);

        ctx.font = '14px Arial';
        ctx.fillStyle = '#666';
        ctx.fillText('Total Tags', centerX, centerY + 20);

        ctx.save();
      },
    },
  ];

  return (
    <div className="enriched-company-donut-chart">
      <div className="chart-container">
        <Doughnut data={chartData} options={options} plugins={plugins} />
      </div>
    </div>
  );
};

export default EnrichedCompanyDonutChart;