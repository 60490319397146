//PhoneInput.js
import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';

const PhoneInput = ({ value, onChange, onBlur, error, helperText, ...props }) => {
  const formatPhoneNumber = (input) => {
    // Remove all non-digit characters
    const cleaned = input.replace(/\D/g, '');
    
    // Format the number
    let formatted = cleaned;
    if (cleaned.length > 3) {
      formatted = `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
    }
    if (cleaned.length > 6) {
      formatted = `${formatted.slice(0, 7)}-${formatted.slice(7)}`;
    }
    
    return formatted;
  };

  const handleChange = (event) => {
    const formatted = formatPhoneNumber(event.target.value);
    onChange(formatted);
  };

  return (
    <TextField
      {...props}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <PhoneOutlinedIcon color="action" />
          </InputAdornment>
        ),
        inputMode: 'tel',
      }}
      inputProps={{
        maxLength: 12, // 10 digits + 2 dashes
      }}
    />
  );
};

export default PhoneInput;