//ContactsTile.js
import React from 'react';
import SummaryTile from './SummaryTile';
import PeopleIcon from '@mui/icons-material/People';
import '../../../styles/tiles/ContactsTile.css';

const ContactsTile = ({ totalContacts, newContacts, onClick }) => {
  const subContent = `${newContacts} new this month`;
  return (
    <SummaryTile
      title="Contacts"
      mainMetric={totalContacts}
      subContent={subContent}
      icon={<PeopleIcon className="summary-tile-icon" />}
      onClick={onClick}
    />
  );
};

export default ContactsTile;



