//TargetCategories.js
import React from 'react';
import { Chip, Paper } from '@mui/material';
import '../../../styles/Targets/TargetCategories.css';

const TargetCategories = ({ data }) => {
    if (!data || data.length === 0) {
      return <Paper className="target-categories"><p>No categories available.</p></Paper>;
    }

    return (
        <Paper className="target-categories">
            {data.map((category, index) => (
            <Chip key={index} label={category} className="category-chip" />
            ))}
        </Paper>
        );
    };

export default TargetCategories;

