// src/components/Dashboard/Targets/TargetLeadLocations.js
import React, { useState } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Typography, CircularProgress, Collapse, IconButton
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TargetLeadContactsTable from './TargetLeadContactsTable';
import { useAppContext } from '../../../context/AppContext';
import '../../../styles/Targets/TargetLeadLocations.css';

const TargetLeadLocations = ({ data, targetId }) => {
  const [expandedLocation, setExpandedLocation] = useState(null);
  const [contactsData, setContactsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { fetchTargetLeadsByLocation } = useAppContext();

  const handleRowClick = async (location) => {
    if (expandedLocation === location) {
      setExpandedLocation(null);
    } else {
      setExpandedLocation(location);
      setLoading(true);
      try {
        const contacts = await fetchTargetLeadsByLocation(targetId, location);
        console.log('Received contacts:', contacts);
        setContactsData(contacts);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  if (!data || data.length === 0) {
    return <Typography>No lead location data available.</Typography>;
  }

  return (
    <TableContainer component={Paper} className="target-lead-locations">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Location</TableCell>
            <TableCell align="right">Lead Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <React.Fragment key={item.Location}>
              <TableRow 
                onClick={() => handleRowClick(item.Location)}
                className="clickable-row"
              >
                <TableCell>
                  <IconButton size="small">
                    {expandedLocation === item.Location ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.Location}
                </TableCell>
                <TableCell align="right">{item.LeadCount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                  <Collapse in={expandedLocation === item.Location} timeout="auto" unmountOnExit>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      contactsData && <TargetLeadContactsTable data={contactsData} />
                    )}
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TargetLeadLocations;