//PricingCard.js
import React from 'react';
import { Card, CardContent, Typography, Button, List, ListItem, ListItemIcon, ListItemText, Tooltip, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import { usePricing } from './PricingContext';

const StyledCard = styled(Card)(({ theme, ispro }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[5],
  },
  ...(ispro === 'true' && {
    border: `2px solid ${theme.palette.primary.main}`,
    position: 'relative',
    overflow: 'hidden',
    '&::after': {
      content: '"Most Popular"',
      position: 'absolute',
      top: '20px',
      right: '-45px',
      background: theme.palette.primary.dark,
      color: theme.palette.common.white,
      padding: '10px 40px',
      transform: 'rotate(45deg)',
      transformOrigin: 'center',
      boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    },
  }),
}));

const SavingsBadge = styled(Box)(({ theme }) => ({
  background: theme.palette.success.main,
  color: theme.palette.common.white,
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  fontWeight: 'bold',
  display: 'inline-block',
  marginBottom: theme.spacing(1),
}));


const PricingCard = ({ title, price, features, allFeatures, isPro = false, onRequestDemo }) => {
  const { crmSize, billingCycle } = usePricing();
  const formatPrice = (value) => value.toLocaleString();

  return (
    <motion.div 
      whileHover={{ scale: 1.02 }}
      transition={{ type: "spring", stiffness: 200 }}
    >
      <StyledCard raised={isPro} ispro={isPro.toString()}>
        <CardContent sx={{ flexGrow: 1, pb: 1 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            {title}
          </Typography>
          <Typography variant="h4" component="div" gutterBottom>
            ${formatPrice(billingCycle === 'annual' ? price.annual : price.monthly)}/month
          </Typography>
          {billingCycle === 'annual' && (
            <Box>
              <SavingsBadge>
                Save ${formatPrice(price.savings)}/year
              </SavingsBadge>
            </Box>
          )}
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Estimated price for {crmSize.toLocaleString()} contacts
          </Typography>
          <List dense>
            {allFeatures.map((feature, index) => (
              <ListItem key={index} disablePadding>
                <ListItemIcon>
                  {features.includes(feature) ? (
                    <CheckCircleOutlineIcon color="primary" />
                  ) : (
                    <CloseIcon color="error" />
                  )}
                </ListItemIcon>
                <ListItemText 
                  primary={feature} 
                  style={{ color: features.includes(feature) ? 'inherit' : '#999' }}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1, mb: 2 }}>
          <Tooltip title="Request a personalized demo" arrow>
            <Button 
              variant="contained"
              style={{ backgroundColor: 'var(--cta-color)' }}
              size="large" 
              sx={{ 
                mb: 1,
                fontWeight: 'bold',
                borderRadius: 2,
                boxShadow: 2,
                '&:hover': {
                  boxShadow: 4,
                  backgroundColor: 'var(--cta-color)',
                },
              }}
              aria-label={`Request demo for ${title}`}
              onClick={() => onRequestDemo(title, crmSize)}
            >
              Request Demo
            </Button>
          </Tooltip>
          {billingCycle === 'annual' ? (
            <>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Billed annually at ${formatPrice(price.annual * 12)}/year
              </Typography>
              <Typography variant="caption" color="text.secondary" align="center" sx={{ mt: 0.5 }}>
                (Final quote provided during demo)
              </Typography>
            </>
          ) : (
            <Typography variant="caption" color="text.secondary" align="center">
              (Final quote provided during demo)
            </Typography>
          )}
        </Box>
      </StyledCard>
    </motion.div>
  );
};

export default PricingCard;