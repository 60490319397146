// src/components/Dashboard/EnrichedCompanies/EnrichedCompanySyncLogs.js
import React, { useMemo } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead,
  TableRow, 
  Paper, 
  Typography, 
  Chip,
  Box
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import '../../../styles/EnrichedCompanies/EnrichedCompanySyncLogs.css';

const EnrichedCompanySyncLogs = ({ data }) => {
  const newValueCount = useMemo(() => {
    return data.filter(log => log.NewValue && log.NewValue !== 'None' && log.NewValue !== '').length;
  }, [data]);

  const formatTimestamp = (timestamp) => {
    const now = new Date();
    const logDate = new Date(timestamp);
    const diffTime = Math.abs(now - logDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) return 'Today';
    if (diffDays === 1) return 'Yesterday';
    if (diffDays <= 7) return `${diffDays} days ago`;
    if (diffDays <= 30) return `${Math.floor(diffDays / 7)} weeks ago`;
    return `${Math.floor(diffDays / 30)} months ago`;
  };

  const getChipColor = (timestamp) => {
    const now = new Date();
    const logDate = new Date(timestamp);
    const diffTime = Math.abs(now - logDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <= 1) return 'success';
    if (diffDays <= 7) return 'primary';
    if (diffDays <= 30) return 'secondary';
    return 'default';
  };

  return (
    <Paper className="enriched-company-sync-logs">
      <Box display="flex" alignItems="center" mb={2}>
        <Chip 
          label={`${newValueCount} New Values`} 
          color="primary" 
          className="count-chip"
        />
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow className="table-header">
              <TableCell>Synced</TableCell>
              <TableCell>Field</TableCell>
              <TableCell>Old Value</TableCell>
              <TableCell>New Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((log, index) => (
              <TableRow key={index} className="sync-log-row">
                <TableCell className="timestamp-cell">
                  <Chip
                    label={formatTimestamp(log.Timestamp)}
                    color={getChipColor(log.Timestamp)}
                    size="small"
                    className="timestamp-chip"
                  />
                </TableCell>
                <TableCell className="field-name-cell">{log.FieldName}</TableCell>
                <TableCell className="old-value-cell">
                  {log.OldValue === 'None' || log.OldValue === '' || !log.OldValue ? (
                    <Chip label="None" size="small" className="none-value-chip" />
                  ) : (
                    log.OldValue
                  )}
                </TableCell>
                <TableCell className="new-value-cell">
                  <Box display="flex" alignItems="center">
                    <ArrowForwardIcon className="arrow-icon" />
                    {log.NewValue}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default EnrichedCompanySyncLogs;