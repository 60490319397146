// src/components/Dashboard/EnrichedCompanies/EnrichedCompanySummary.js
import React from 'react';
import { Card, CardContent, Typography, Grid, Box, Chip, Tooltip } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EnrichedCompanyDonutChart from './EnrichedCompanyDonutChart';
import EnrichedCompanySocialIcons from './EnrichedCompanySocialIcons';
import EnrichedCompanyFieldDetails from './EnrichedCompanyFieldDetails';
import '../../../styles/EnrichedCompanies/EnrichedCompanySummary.css';

const EnrichedCompanySummary = ({ data, targets }) => {
  const socialData = {
    LinkedInURL: data.LinkedInURL,
    InstagramURL: data.InstagramURL,
    FacebookURL: data.FacebookURL,
    TwitterURL: data.TwitterURL,
    TikTokURL: data.TikTokURL,
    VimeoURL: data.VimeoURL,
  };

  const formatLastModified = (lastModified) => {
    if (!lastModified) return 'Never';
    
    const now = new Date();
    const modifiedDate = new Date(lastModified);
    const diffTime = Math.abs(now - modifiedDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) return 'Today';
    if (diffDays === 1) return 'Yesterday';
    if (diffDays <= 7) return `${diffDays} days ago`;
    if (diffDays <= 30) return `${Math.floor(diffDays / 7)} weeks ago`;
    if (diffDays <= 365) return `${Math.floor(diffDays / 30)} month ago`;
    return `${Math.floor(diffDays / 365)} years ago`;
  };

  return (
    <Card className="enriched-company-summary">
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h5" className="company-name">{data.CompanyName}</Typography>
          <Tooltip title="Last Sync" arrow>
            <Chip
              icon={<AccessTimeIcon />}
              label={formatLastModified(data.LastModified)}
              className="last-modified-chip"
              size="small"
            />
          </Tooltip>
        </Box>
        <EnrichedCompanyFieldDetails data={data} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box className="summary-stats">
              <Box className="stat-item">
                <Typography variant="h6">{data.CategoryCount}</Typography>
                <Typography variant="body2">Categories</Typography>
              </Box>
              <Box className="stat-item">
                <Typography variant="h6">{data.ClientCount}</Typography>
                <Typography variant="body2">Clients</Typography>
              </Box>
              <Box className="stat-item">
                <Typography variant="h6">{data.ContactCount}</Typography>
                <Typography variant="body2">Contacts</Typography>
              </Box>
            </Box>
            <EnrichedCompanySocialIcons socialData={socialData} />
          </Grid>
          <Grid item xs={12} md={6}>
            <EnrichedCompanyDonutChart data={targets} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EnrichedCompanySummary;