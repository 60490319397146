// src/components/Dashboard/Overview.js
import React from 'react';
import { useAppContext } from '../../context/AppContext';
import CompaniesTile from './tiles/CompaniesTile';
import ContactsTile from './tiles/ContactsTile';
import EnrichedCompaniesTile from './tiles/EnrichedCompaniesTile';
import EnrichedContactsTile from './tiles/EnrichedContactsTile';
import TargetsTile from './tiles/TargetsTile';
import ActiveSubscribersTile from './tiles/ActiveSubscribersTile';
import BouncedSubscribersTile from './tiles/BouncedSubscribersTile';
import UnsubscribedSubscribersTile from './tiles/UnsubscribedSubscribersTile';
import '../../styles/Overview.css';

const Overview = () => {
  const { companies, contacts, enrichedCompanies, enrichedContacts, targets, subscribers } = useAppContext();

  const calculateEnrichmentPercentage = (enriched, total) => {
    return total > 0 ? Math.round((enriched / total) * 100) : 0;
  };

  const calculatePercentage = (part, total) => {
    return total > 0 ? Math.round((part / total) * 100) : 0;
  };

  const activeSubscribers = subscribers.stats?.active?.total || 0;
  const bouncedSubscribers = subscribers.stats?.bounced?.total || 0;
  const unsubscribedSubscribers = subscribers.stats?.unsubscribed?.total || 0;
  const totalSubscribers = activeSubscribers + bouncedSubscribers + unsubscribedSubscribers;

  return (
    <div className="overview">
      <CompaniesTile totalCompanies={companies.stats?.totalCompanies || 0} newCompanies={companies.stats?.newCompanies || 0} />
      <ContactsTile totalContacts={contacts.stats?.totalContacts || 0} newContacts={contacts.stats?.newContacts || 0} />
      <EnrichedCompaniesTile 
        enrichedCompanies={enrichedCompanies.stats?.total} 
        enrichmentPercentage={calculateEnrichmentPercentage(enrichedCompanies.stats?.total, companies.stats?.totalCompanies || 0)} 
      />
      <EnrichedContactsTile 
        enrichedContacts={enrichedContacts.total} 
        enrichmentPercentage={calculateEnrichmentPercentage(enrichedContacts.total, contacts.stats?.totalContacts || 0)} 
      />
      <TargetsTile totalTargets={targets.total} newTargets={targets.newLeads} />
      <ActiveSubscribersTile 
        activeSubscribers={activeSubscribers} 
        newSubscribers={subscribers.stats?.active?.new || 0}
        subscriberPercentage={calculatePercentage(activeSubscribers, totalSubscribers)} 
      />
      <BouncedSubscribersTile 
        bouncedSubscribers={bouncedSubscribers} 
        newBouncedSubscribers={subscribers.stats?.bounced?.new || 0}
        bouncePercentage={calculatePercentage(bouncedSubscribers, totalSubscribers)} 
      />
      <UnsubscribedSubscribersTile 
        unsubscribedSubscribers={unsubscribedSubscribers} 
        newUnsubscribedSubscribers={subscribers.stats?.unsubscribed?.new || 0}
        unsubscribePercentage={calculatePercentage(unsubscribedSubscribers, totalSubscribers)} 
      />
    </div>
  );
};

export default Overview;