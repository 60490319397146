//EnrichedCompaniesTile.js
import React from 'react';
import SummaryTile from './SummaryTile';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import '../../../styles/tiles/EnrichedCompaniesTile.css';

const EnrichedCompaniesTile = ({ enrichedCompanies, enrichmentPercentage, onClick }) => {
  const subContent = `${enrichmentPercentage}% enriched`;
  return (
    <SummaryTile
      title="Enriched Companies"
      mainMetric={enrichedCompanies}
      subContent={subContent}
      icon={<VerifiedUserIcon className="summary-tile-icon" />}
      onClick={onClick}
    />
  );
};

export default EnrichedCompaniesTile;


