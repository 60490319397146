// src/components/Dashboard/Targets/TargetLeadDisplay.js
import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import TargetLeadTitles from './TargetLeadTitles';
import TargetLeadLocations from './TargetLeadLocations';
import TargetLeadClients from './TargetLeadClients';
import TargetLeadCategories from './TargetLeadCategories';
import '../../../styles/Targets/TargetLeadDisplay.css';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`lead-tabpanel-${index}`}
      aria-labelledby={`lead-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const TargetLeadDisplay = ({ data, targetId }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="target-lead-display">
      <Tabs value={value} onChange={handleChange} aria-label="Lead display tabs">
        <Tab label="Titles" id="lead-tab-0" aria-controls="lead-tabpanel-0" />
        <Tab label="Locations" id="lead-tab-1" aria-controls="lead-tabpanel-1" />
        <Tab label="Clients" id="lead-tab-2" aria-controls="lead-tabpanel-2" />
        <Tab label="Categories" id="lead-tab-3" aria-controls="lead-tabpanel-3" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <TargetLeadTitles data={data.leadTitles} targetId={targetId} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TargetLeadLocations data={data.leadLocations} targetId={targetId} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TargetLeadClients data={data.leadClients} targetId={targetId} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TargetLeadCategories data={data.leadCategories} targetId={targetId} />
      </TabPanel>
    </div>
  );
};

export default TargetLeadDisplay;