
// ContactProfileFields.js
import React, {useState} from 'react';
import { Typography, Tooltip } from '@mui/material';
import './ProfileFields.css';


const ContactProfileLogoToggle = ({ fieldName, enrichedValue, originalValue, onSelectValue }) => {
  const [selectedValue, setSelectedValue] = useState(enrichedValue && enrichedValue !== 'Unknown' ? 'enriched' : 'original');

  const handleSelectValue = (valueType) => {
    setSelectedValue(valueType);
    onSelectValue(valueType === 'enriched' ? (enrichedValue !== 'Unknown' ? enrichedValue : 'None') : (originalValue !== 'Unknown' ? originalValue : 'None'));
  };

  return (
    <div className="logos">
        <img
          src="codebuddy_logo_1.png"
          alt="CodeBuddy"
          className={`logo ${selectedValue === 'enriched' ? '' : 'logo-gray'}`}
          onClick={() => handleSelectValue('enriched')}
        />
        <img
          src="salesforce_logo.png"
          alt="Salesforce"
          className={`logo ${selectedValue === 'original' ? '' : 'logo-gray'}`}
          onClick={() => handleSelectValue('original')}
        />
    </div>
  );
};


const ContactProfileIndividualField = ({ fieldName, enrichedValue, originalValue }) => {
  const [displayValue, setDisplayValue] = useState(enrichedValue && enrichedValue !== 'Unknown' ? enrichedValue : originalValue && originalValue !== 'Unknown' ? originalValue : 'None');

  return (
    <div className="individual-field">
      <ContactProfileLogoToggle
        fieldName={fieldName}
        enrichedValue={enrichedValue}
        originalValue={originalValue}
        onSelectValue={(value) => setDisplayValue(value || 'None')}
      />
      <div className="field-content">
        <Tooltip title={fieldName}>
          <Typography variant="body2" component="span" className={`field-value ${displayValue === 'None' ? 'value-gray' : ''}`}>
            {displayValue}
          </Typography>
        </Tooltip>
      </div>
      <Typography variant="body2" className="field-name-right">{fieldName}</Typography>
    </div>
  );
};


const ContactProfileFields = ({ contact }) => {
    return (
      <div className="contact-fields">
        <ContactProfileIndividualField fieldName="Job Title" enrichedValue={contact.EnrichedTitle} originalValue={contact.Title} />
        <ContactProfileIndividualField fieldName="Company Name" enrichedValue={contact.EnrichedCompanyName} originalValue={contact.CompanyName} />
        <ContactProfileIndividualField fieldName="Company Website" enrichedValue={contact.EnrichedCompanyWebsite} originalValue={contact.CompanyWebsite} />
        <ContactProfileIndividualField fieldName="Email Address" enrichedValue={contact.EnrichedEmail} originalValue={contact.Email} />
        <ContactProfileIndividualField fieldName="Geographic Location" enrichedValue={contact.EnrichedGeographicMarket} originalValue={contact.GeographicMarket} />
        <ContactProfileIndividualField fieldName="Personal Website" enrichedValue={contact.PersonalWebsite} originalValue={contact.SocialLinks} />
      </div>
    );
  };
  
  export default ContactProfileFields;