//DragDropDataContext.js

import React, { createContext, useState, useContext } from 'react';

const DragDropDataContext = createContext();

export const useDragDropData = () => useContext(DragDropDataContext);

export const DragDropDataProvider = ({ children }) => {
  const [draggingItem, setDraggingItem] = useState(null);

  const startDragging = (itemType) => {
    setDraggingItem(itemType);
  };

  const stopDragging = () => {
    setDraggingItem(null);
  };

  return (
    <DragDropDataContext.Provider value={{ draggingItem, startDragging, stopDragging }}>
      {children}
    </DragDropDataContext.Provider>
  );
};
