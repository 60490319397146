//HowItWorks.js
import React from 'react';
import { Container, Grid, Typography, Box, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import PsychologyIcon from '@mui/icons-material/Psychology';
import HubIcon from '@mui/icons-material/Hub';
import LandingDemoButton from './LandingDemoButton';

const HowItWorks = () => {
  const theme = useTheme();

  const steps = [
    {
      icon: <CloudSyncIcon fontSize="large" style={{ color: theme.palette.primary.main }} />,
      title: 'AI-Powered Enrichment',
      description: 'Our advanced AI continuously scans the web to update and improve your CRM data, ensuring you always have the most current and accurate information.',
      highlight: 'Real-time Updates'
    },
    {
      icon: <PsychologyIcon fontSize="large" style={{ color: theme.palette.primary.main }} />,
      title: 'Smart Segmentation',
      description: 'Leverage AI to create dynamic, highly targeted customer groups, enabling personalized outreach that significantly boosts engagement and conversion rates.',
      highlight: 'Boosted Engagement'
    },
    {
      icon: <HubIcon fontSize="large" style={{ color: theme.palette.primary.main }} />,
      title: 'Real-Time Integration',
      description: 'Connect your CRM with existing tools like Salesforce and Campaign Monitor, creating a centralized hub that enhances insights and optimizes your sales process.',
      highlight: 'Unified Workflow'
    }
  ];

  return (
    <section className="how-it-works" style={{ backgroundColor: theme.palette.background.default }}>
      <Container maxWidth="lg">
        <Typography variant="h2" align="center" gutterBottom className="section-title" sx={{ mb: 6 }}>
          How AgencyEdge Works
        </Typography>
        <Grid container spacing={4}>
          {steps.map((step, index) => (
            <Grid item xs={12} md={4} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                <Box
                  className="how-it-works-box"
                  sx={{
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: '16px',
                    padding: '32px',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: '0 12px 30px rgba(0, 0, 0, 0.15)',
                    }
                  }}
                >
                  {step.icon}
                  <Typography variant="h5" gutterBottom sx={{ mt: 2, fontWeight: 600 }}>
                    {step.title}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 3, flexGrow: 1 }}>
                    {step.description}
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.common.white,
                      borderRadius: '20px',
                      padding: '8px 16px',
                      fontWeight: 600,
                      fontSize: '0.9rem'
                    }}
                  >
                    {step.highlight}
                  </Box>
                </Box>
              </motion.div>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <LandingDemoButton size="large" />
          </motion.div>
        </Box>
      </Container>
    </section>
  );
};

export default HowItWorks;