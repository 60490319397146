//LandingDemoButton.js
import React, { useState } from 'react';
import { Button } from '@mui/material';
import RequestDemoForm from './RequestDemoForm';

const LandingDemoButton = ({ size = 'medium' }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const buttonStyles = {
    small: {
      fontSize: '0.875rem',
      padding: '8px 16px',
    },
    medium: {
      fontSize: '1rem',
      padding: '10px 20px',
    },
    large: {
      fontSize: '1.25rem',
      padding: '12px 24px',
    },
  };

  return (
    <>
      <Button
        variant="contained"
        style={{ backgroundColor: 'var(--cta-color)', ...buttonStyles[size] }}
        onClick={handleOpenDialog}
        sx={{
          fontWeight: 'bold',
          borderRadius: 2,
          boxShadow: 2,
          '&:hover': {
            boxShadow: 4,
          },
        }}
      >
        Request Demo
      </Button>
      <RequestDemoForm
        open={isDialogOpen}
        onClose={handleCloseDialog}
        plan="General"
      />
    </>
  );
};

export default LandingDemoButton;