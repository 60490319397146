// src/components/Dashboard/Sidebar.js
import React from 'react';
import '../../styles/Sidebar.css';

const Sidebar = ({ setCurrentSection }) => {
  return (
    <div className="sidebar">
      <h2>CodeBuddy</h2>
      <nav>
        <ul>
          <li onClick={() => setCurrentSection('Overview')}>Overview</li>
          <li onClick={() => setCurrentSection('Companies')}>Companies</li>
          <li onClick={() => setCurrentSection('EnrichedCompanies')}>Enriched Companies</li>
          <li onClick={() => setCurrentSection('Contacts')}>Contacts</li>
          <li onClick={() => setCurrentSection('Targets')}>Targets</li>
          <li onClick={() => setCurrentSection('Subscribers')}>Subscribers</li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;