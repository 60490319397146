// src/components/Dashboard/Targets/TargetSummary.js
import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import TargetLeadDonutChart from './TargetLeadDonutChart';
import '../../../styles/Targets/TargetSummary.css';

const TargetSummary = ({ data, leadTypes }) => {
  return (
    <Card className="target-summary">
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>{data.TargetName}</Typography>
            <Typography variant="subtitle1" gutterBottom>Tag: {data.TargetTag}</Typography>
            <Box className="summary-stats">
              <Box className="stat-item">
                <Typography variant="h6">{data.CategoryCount}</Typography>
                <Typography variant="body2">Categories</Typography>
              </Box>
              <Box className="stat-item">
                <Typography variant="h6">{data.ClientCount}</Typography>
                <Typography variant="body2">Clients</Typography>
              </Box>
              <Box className="stat-item">
                <Typography variant="h6">{data.CampaignCount}</Typography>
                <Typography variant="body2">Campaigns</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <TargetLeadDonutChart data={leadTypes} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TargetSummary;