// src/components/Dashboard/EnrichedCompanies/EnrichedCompanyFieldDetails.js
import React from 'react';
import { Typography, Grid } from '@mui/material';
import '../../../styles/EnrichedCompanies/EnrichedCompanyFieldDetails.css';

const FieldItem = ({ label, value }) => (
  <div className="field-item">
    <Typography variant="subtitle2" className="field-label">{label}</Typography>
    <Typography variant="body1" className="field-value">{value || 'N/A'}</Typography>
  </div>
);

const formatAnnualRevenue = (revenue) => {
  if (!revenue || revenue === 0) return 'N/A';
  return `$${revenue.toLocaleString('en-US', { maximumFractionDigits: 0 })}`;
};

const EnrichedCompanyFieldDetails = ({ data }) => {
  return (
    <Grid container spacing={2} className="company-field-details">
      <Grid item xs={12} sm={6} md={3}>
        <FieldItem label="Type" value={data.CompanyType} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FieldItem label="Website" value={data.CompanyWebsite} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FieldItem label="Location" value={data.Headquarters} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FieldItem label="Address" value={data.MailingAddress} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FieldItem label="Annual Revenue" value={formatAnnualRevenue(data.AnnualRevenue)} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FieldItem label="Employees" value={data.EstimatedNumberOfEmployees} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FieldItem label="Email" value={data.Email} />
      </Grid>
    </Grid>
  );
};

export default EnrichedCompanyFieldDetails;