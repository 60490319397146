//CustomCompanyDataContext.js

import React, { createContext, useState, useContext } from 'react';

const CustomCompanyDataContext = createContext(null);

export const useCustomCompanyData = () => useContext(CustomCompanyDataContext);

export const CustomCompanyDataProvider = ({ children }) => {
  const [customCompanySalesforceData, setCustomCompanySalesforceData] = useState(null);
  const [customCompanySalesforceDataLastUpdated, setCustomCompanySalesforceDataLastUpdated] = useState(null);
  const [customCompanyAnymailfinderData, setCustomCompanyAnymailfinderData] = useState([]); // [
  const [customCompanyAnymailfinderDataLastUpdated, setCustomCompanyAnymailfinderDataLastUpdated] = useState(null);
  const [serviceChainInfo, setServiceChainInfo] = useState(null);



  const updateCustomCompanySalesforceData = (data) => {
    setCustomCompanySalesforceData(data);
    setCustomCompanySalesforceDataLastUpdated(new Date()); // Set current time as last updated time
  };

  const updatecustomCompanyAnymailfinderData = (data) => {
    setCustomCompanyAnymailfinderData(data);
    setCustomCompanyAnymailfinderDataLastUpdated(new Date()); // Set current time as last updated time
  };


  return (
    <CustomCompanyDataContext.Provider value={{ 
        customCompanySalesforceData, 
        setCustomCompanySalesforceData: updateCustomCompanySalesforceData, customCompanySalesforceDataLastUpdated,
        customCompanyAnymailfinderData,
        setCustomCompanyAnymailfinderData: updatecustomCompanyAnymailfinderData, customCompanyAnymailfinderDataLastUpdated,
        serviceChainInfo, setServiceChainInfo
    }}>
      {children}
    </CustomCompanyDataContext.Provider>
  );
};