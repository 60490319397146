// CodeBuddyDataContext.js
import React, { createContext, useState, useContext } from 'react';

const CodeBuddyDataContext = createContext(null);

export const useCodeBuddyData = () => useContext(CodeBuddyDataContext);

export const CodeBuddyDataProvider = ({ children }) => {
  const [codeBuddyData, setCodeBuddyData] = useState(null);
  const [codeBuddyCompanies, setCodeBuddyCompanies] = useState(null);
  const [codeBuddyStatistics, setCodeBuddyStatistics] = useState(null);
  const [codeBuddyProcessFinished, setCodeBuddyProcessFinished] = useState(false);
  const [codeBuddyProcessReady, setCodeBuddyProcessReady] = useState(false); // New state
  const [isCodeBuddyProcessing, setIsCodeBuddyProcessing] = useState(false);
  const [codeBuddySelectedData, setCodeBuddySelectedData] = useState([]); // New state
  const [selectedCodeBuddyRows, setSelectedCodeBuddyRows] = useState(new Set());
  const [codeBuddyNewWebsites, setCodeBuddyNewWebsites] = useState([]); // New state
  const [codeBuddyServiceInfo, setCodeBuddyServiceInfo] = useState(null); // New state
  const [codeBuddySalesforceProcessReady, setCodeBuddySalesforceProcessReady] = useState(false); // New state
  const [codeBuddyCampaignMonitorProcessReady, setCodeBuddyCampaignMonitorProcessReady] = useState(false); // New state
  const [syncBackToSalesforceProcessReady, setSyncBackToSalesforceProcessReady] = useState(false); // New state
  const [syncBackToCampaignMonitorProcessReady, setSyncBackToCampaignMonitorProcessReady] = useState(false); // New state
  const [codeBuddyCompanySelectedData, setCodeBuddyCompanySelectedData] = useState([]); // New state

  console.log('CodeBuddyDataContext Provider Rendering');
  console.log({ codeBuddyData, codeBuddyProcessReady, isCodeBuddyProcessing, /* other states */ });

  return (
    <CodeBuddyDataContext.Provider value={{ codeBuddyData, setCodeBuddyData, codeBuddyCompanies, setCodeBuddyCompanies, codeBuddyProcessReady, setCodeBuddyProcessReady, isCodeBuddyProcessing, setIsCodeBuddyProcessing, codeBuddySelectedData, setCodeBuddySelectedData, selectedCodeBuddyRows, setSelectedCodeBuddyRows, codeBuddyStatistics, setCodeBuddyStatistics, codeBuddyProcessFinished, setCodeBuddyProcessFinished, codeBuddyNewWebsites, setCodeBuddyNewWebsites, codeBuddyServiceInfo, setCodeBuddyServiceInfo, codeBuddySalesforceProcessReady, setCodeBuddySalesforceProcessReady, codeBuddyCampaignMonitorProcessReady, setCodeBuddyCampaignMonitorProcessReady, syncBackToSalesforceProcessReady, setSyncBackToSalesforceProcessReady, codeBuddyCompanySelectedData, setCodeBuddyCompanySelectedData, syncBackToCampaignMonitorProcessReady, setSyncBackToCampaignMonitorProcessReady }}>
      {children}
    </CodeBuddyDataContext.Provider>
  );
};
