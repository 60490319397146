//PricingSlider.js
import React, { useState, useEffect, useCallback } from 'react';
import { Slider, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import { motion, AnimatePresence } from 'framer-motion';
import { usePricing } from './PricingContext';

const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};




const StyledSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 48,
    height: 48,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: theme.palette.primary.main,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
}));

const DynamicLabel = styled(motion.span)(({ theme }) => ({
  display: 'inline-block',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  background: theme.palette.background.paper,
  padding: '8px 16px',
  borderRadius: '8px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  marginLeft: theme.spacing(2),
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
    transform: 'translateY(-2px)',
  },
}));


const AnimatedNumber = ({ value }) => (
  <motion.span
    key={value}
    initial={{ opacity: 0, y: 40 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -40 }}
    transition={{ duration: 0.05 }}
  >
    {formatNumber(value)}
  </motion.span>
);

const PricingSlider = ({ context = 'pricing', onSliderChange }) => {
  const { crmSize, setCrmSize } = usePricing();
  const [displayValue, setDisplayValue] = useState(crmSize);

  const handleSliderChange = useCallback((event, newValue) => {
    setDisplayValue(newValue);
    if (onSliderChange) {
      onSliderChange(newValue);
    }
  }, [onSliderChange]);

  const handleSliderChangeCommitted = useCallback((event, newValue) => {
    setCrmSize(newValue);
  }, [setCrmSize]);

  useEffect(() => {
    setDisplayValue(crmSize);
  }, [crmSize]);

  const marks = [
    { value: 1000, label: '1k' },
    { value: 25000, label: '25k' },
    { value: 50000, label: '50k' },
    { value: 75000, label: '75k' },
    { value: 100000, label: '100k' },
  ];

  const containerStyles = {
    width: '100%',
    ...(context === 'requestDemo' ? {
      backgroundColor: (theme) => theme.palette.background.paper,
      borderRadius: 2,
      p: 2,
      boxShadow: 1,
    } : {}),
  };

  return (
    <Box sx={containerStyles}>
      <Typography variant="h6" gutterBottom align="center" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
        CRM Size 
        <DynamicLabel>
          <AnimatePresence mode="wait">
            <AnimatedNumber value={displayValue} />
          </AnimatePresence>
          {' Contacts'}
        </DynamicLabel>
      </Typography>
      <StyledSlider
        value={displayValue}
        onChange={handleSliderChange}
        onChangeCommitted={handleSliderChangeCommitted}
        aria-labelledby="crm-size-slider"
        valueLabelDisplay="auto"
        valueLabelFormat={formatNumber}
        step={1000}
        marks={marks}
        min={1000}
        max={100000}
      />
    </Box>
  );
};

export default React.memo(PricingSlider);

