// src/components/Dashboard/TargetsList.js
import React, { useState, useMemo } from 'react';
import { useAppContext } from '../../context/AppContext';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, CircularProgress, TextField } from '@mui/material';

const TargetsList = ({ onTargetClick }) => {
  const { targetsList } = useAppContext();
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredTargets = useMemo(() => {
    return targetsList.list.filter(target =>
      (target.TargetName?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
      (target.TargetTag?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false)
    );
  }, [targetsList.list, searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  if (!targetsList.list.length) {
    return <CircularProgress />;
  }

  return (
    <Paper>
      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        label="Search targets"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Target Name</TableCell>
              <TableCell>Target Tag</TableCell>
              <TableCell>Total Tags</TableCell>
              <TableCell>Existing Tags</TableCell>
              <TableCell>Company Category Tags</TableCell>
              <TableCell>Contact Category Tags</TableCell>
              <TableCell>Client Match Leads</TableCell>
              <TableCell>Email Click Tags</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTargets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((target) => (
              <TableRow 
                key={target.TargetID} 
                onClick={() => onTargetClick(target.TargetID)}
                style={{ cursor: 'pointer' }}
              >
                <TableCell>{target.TargetName}</TableCell>
                <TableCell>{target.TargetTag}</TableCell>
                <TableCell>{target.TotalTags}</TableCell>
                <TableCell>{target.ExistingTags}</TableCell>
                <TableCell>{target.CompanyCategoryTags}</TableCell>
                <TableCell>{target.ContactCategoryTags}</TableCell>
                <TableCell>{target.ClientMatchLeads}</TableCell>
                <TableCell>{target.EmailClickTags}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={targetsList.totalTargets}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25]}
      />
    </Paper>
  );
};

export default TargetsList;