//TargetClients.js
import React from 'react';
import { List, ListItem, ListItemText, Paper } from '@mui/material';
import '../../../styles/Targets/TargetClients.css';

const TargetClients = ({ data }) => {
    if (!data || data.length === 0) {
      return <Paper className="target-clients"><p>No clients available.</p></Paper>;
    }
  
    return (
      <Paper className="target-clients">
        <List>
          {data.map((client, index) => (
            <ListItem key={index}>
              <ListItemText primary={client} />
            </ListItem>
          ))}
        </List>
      </Paper>
    );
  };

export default TargetClients;