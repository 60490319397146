// LinkedInRedirect.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SalesforceRedirect.css'; // Ensure you have the corresponding CSS
import { useUserAuthenticationData } from './UserAuthenticationDataContext';
import { useLinkedInData } from './LinkedInDataContext';
import { useCampaignMonitorData } from './CampaignMonitorDataContext';
import { useSalesforceData } from './SalesforceDataContext';

function LinkedInRedirect({ setIsAuthenticated }) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setUserFirstName } = useUserAuthenticationData();
  const { linkedInIsConnected, setLinkedInIsConnected } = useLinkedInData();
  const { campaignMonitorIsConnected, setCampaignMonitorIsConnected } = useCampaignMonitorData();
  const { salesforceIsConnected, setSalesforceIsConnected } = useSalesforceData();



  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const storedState = localStorage.getItem('linkedin_auth_state');  // Retrieve the stored state parameter

    if (code && state === storedState) {
      completeLinkedInAuthentication(code);
    } else {
      setIsLoading(false);
      setError('Invalid state parameter');
    }
  }, []);

  const completeLinkedInAuthentication = async (code) => {
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/linkedin/linkedin-callback`;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    'UserId': localStorage.getItem('userId') },
        body: JSON.stringify({ code }),
      });

      const data = await response.json();

      if (data.status === 'success') {
        localStorage.setItem('linkedin_access_token', data.access_token);
        setIsAuthenticated(true);
        setUserFirstName(localStorage.getItem('userFirstName')); // Set userFirstName again
        setLinkedInIsConnected(true);

        // store linkedInIsConnected in localStorage
        localStorage.setItem('linkedInIsConnected', linkedInIsConnected);

        // get salesforceIsConnected and campaignMonitorIsConnected from localStorage
        const salesforceIsConnected = localStorage.getItem('salesforceIsConnected');
        const campaignMonitorIsConnected = localStorage.getItem('campaignMonitorIsConnected');

        // set salesforceIsConnected and campaignMonitorIsConnected to true if they are true. Otherwise, set them to false
        setSalesforceIsConnected(salesforceIsConnected === 'true');
        setCampaignMonitorIsConnected(campaignMonitorIsConnected === 'true');

        



        navigate('/'); // Navigate to home or another page on success
      } else {
        setError('Failed to authenticate with LinkedIn.');
      }
    } catch (err) {
      console.error('Error:', err);
      setError('An error occurred while connecting to LinkedIn.');
      navigate('/'); // Navigate to home page on catch
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div className="linkedin-redirect-container">Connecting to LinkedIn...</div>;
  }

  if (error) {
    return <div className="linkedin-redirect-container error">{error}</div>;
  }

  return null; // Render nothing if not loading or error
}

export default LinkedInRedirect;
