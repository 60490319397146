// src/components/Dashboard/EnrichedCompanies/EnrichedCompanyContacts.js
import React, { useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Chip, Tooltip } from '@mui/material';
import '../../../styles/EnrichedCompanies/EnrichedCompanyContacts.css';

const tagColors = {
  'DG': '#FF6B6B',
  'JL': '#4ECDC4',
  'AA': '#45B7D1',
  'DD': '#FFA07A',
  'CM': '#98D8C8',
  'Day19': '#F7DC6F',
  'AD': '#C39BD3',
  'DJ': '#7FB3D5',
  'ZA': '#F1948A',
  'ZS': '#DAF7A6',
  'DS': '#FF9FF3',
  'SB': '#FFC300',
  'POP': '#DAE8FC',
  'KJ': '#D5A6BD',
  'HE': '#B5EAD7',
  'AG': '#FFB6B9',
  'DM': '#C7CEEA',
  'TT': '#FFF5BA',
  'CC': '#FF9AA2',
  'Key': '#E2F0CB'
};

const getTagFullName = (tag) => {
  const tagNames = {
    'DG': 'Dan Goldberg',
    'JL': 'Jason Lindsey',
    'AA': 'Andy Anderson',
    'DD': 'Doug Menuez',
    'CM': 'Cade Martin',
    'Day19': 'Day19',
    'AD': 'Andrei Duman',
    'DJ': 'David Johnson',
    'ZA': 'Zach Anderson',
    'ZS': 'Zach Scott',
    'DS': 'Dan Saelinger',
    'SB': 'Sandro Baebler',
    'POP': 'Pop Creative Studio',
    'KJ': 'Kremer Johnson',
    'HE': 'Heather Elder',
    'AG': 'Andy Glass',
    'DM': 'David Martinez',
    'TT': 'Tim Tadder',
    'CC': 'Chris Crisman',
    'Key': 'Key'
  };
  return tagNames[tag] || tag;
};

const TagChips = ({ tags }) => {
  console.log("Received tags:", tags); // Debugging log

  // Ensure tags is an array of individual tags
  const tagArray = useMemo(() => {
    if (Array.isArray(tags)) {
      return tags.flatMap(tag => tag.split(',').map(t => t.trim()));
    } else if (typeof tags === 'string') {
      return tags.split(',').map(t => t.trim());
    }
    return [];
  }, [tags]);

  console.log("Processed tag array:", tagArray); // Debugging log

  return (
    <Box display="flex" flexWrap="wrap" gap={1}>
      {tagArray.map((tag, index) => {
        console.log(`Rendering tag: ${tag}, Full Name: ${getTagFullName(tag)}`); // Debugging log
        return (
          <Tooltip key={index} title={getTagFullName(tag)} arrow>
            <Chip
              label={tag}
              size="small"
              style={{
                backgroundColor: tagColors[tag] || '#E0E0E0',
                color: tagColors[tag] ? '#FFF' : '#000',
              }}
            />
          </Tooltip>
        );
      })}
    </Box>
  );
};

const EnrichedCompanyContacts = ({ data }) => {
  const contactsCount = useMemo(() => data.length, [data]);

  return (
    <Paper className="enriched-company-contacts">
      <Box display="flex" alignItems="center" mb={2} p={2}>
        <Chip 
          label={`${contactsCount} Contacts`} 
          color="primary" 
          className="count-chip"
        />
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow className="table-header">
              <TableCell>Full Name</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Tags</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((contact, index) => {
              console.log(`Contact ${index}:`, contact); // Debugging log
              return (
                <TableRow key={index} className="contact-row" hover>
                  <TableCell>{contact.FullName}</TableCell>
                  <TableCell>{contact.Title}</TableCell>
                  <TableCell>{contact.Email}</TableCell>
                  <TableCell>
                    <TagChips tags={contact.Tags} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default EnrichedCompanyContacts;