// src/components/Dashboard/Targets/TargetLeadContactsTable.js
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Link } from '@mui/material';
import '../../../styles/Targets/TargetLeadContactsTable.css';

const TargetLeadContactsTable = ({ data }) => {
  console.log('TargetLeadContactsTable received data:', data);

  if (!data || data.length === 0) {
    console.log('No contact data available');
    return <Typography>No contact data available.</Typography>;
  }

  return (
    <TableContainer component={Paper} className="target-lead-contacts-table">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>LinkedIn</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Lead Types</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((contact, index) => (
            <TableRow key={index}>
              <TableCell>{`${contact.FirstName} ${contact.LastName}`}</TableCell>
              <TableCell>{contact.Title}</TableCell>
              <TableCell>{contact.CompanyName}</TableCell>
              <TableCell>{contact.Location}</TableCell>
              <TableCell>{contact.Email}</TableCell>
              <TableCell>
                {contact.LinkedInURL && (
                  <Link href={contact.LinkedInURL} target="_blank" rel="noopener noreferrer">
                    Profile
                  </Link>
                )}
              </TableCell>
              <TableCell>{contact.State}</TableCell>
              <TableCell>{contact.LeadTypes.join(', ')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TargetLeadContactsTable;