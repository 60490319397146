//Navbar.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserAuthenticationData } from './UserAuthenticationDataContext';
import './Navbar.css';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = () => {
  const { isAuthenticated, setIsAuthenticated } = useUserAuthenticationData();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/user_authentication/logout`);
      if (response.data.success) {
        setIsAuthenticated(false);
        localStorage.removeItem('userFirstName'); // Clear user first name from localStorage
        localStorage.removeItem('linkedin_access_token');
        localStorage.removeItem('campaign_monitor_access_token');
        localStorage.removeItem('linkedin_access_token');
        localStorage.removeItem('linkedin_auth_state');

        handleClose();
      } else {
        setError("Error during logout");
      }
    } catch (error) {
      setError('Logout error: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <nav className="navbar">
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenu}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {isAuthenticated ? (
          <>
            <MenuItem onClick={() => { navigate('/'); handleClose(); }}>Home</MenuItem>
            <MenuItem onClick={() => { navigate('/frequently-asked-questions'); handleClose(); }}>Help</MenuItem>
            <MenuItem onClick={() => { navigate('/settings'); handleClose(); }}>Settings</MenuItem>
            <MenuItem onClick={handleLogout}>
              {loading ? <CircularProgress size={20} /> : 'Logout'}
            </MenuItem>
          </>
        ) : (
          <MenuItem onClick={() => { navigate('/'); handleClose(); }}>Login</MenuItem>
        )}
      </Menu>
      {error && <div className="nav-error">{error}</div>}
    </nav>
  );
};

export default Navbar;


