// src/components/Blog/BlogListCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Card, CardContent, CardMedia, Typography, Box, Tooltip, IconButton, Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowForward, CalendarToday, Person } from '@mui/icons-material';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

const BlogListCard = ({ post, loading }) => {
  return (
    <StyledCard elevation={2}>
      {loading ? (
        <Skeleton variant="rectangular" height={200} />
      ) : (
        <CardMedia
          component="img"
          height="200"
          image={post.featuredImage}
          alt={post.title}
        />
      )}
      <CardContent sx={{ flexGrow: 1 }}>
        {loading ? (
          <>
            <Skeleton variant="text" height={32} width="80%" />
            <Skeleton variant="text" height={20} />
            <Skeleton variant="text" height={20} />
            <Skeleton variant="text" height={20} width="60%" />
          </>
        ) : (
          <>
            <Typography gutterBottom variant="h5" component="h2">
              {post.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" paragraph>
              {post.excerpt}
            </Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
              <Box display="flex" alignItems="center">
                <Tooltip title="Publication date">
                  <Box display="flex" alignItems="center" mr={2}>
                    <CalendarToday fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography variant="caption">
                      {new Date(post.publishedAt).toLocaleDateString()}
                    </Typography>
                  </Box>
                </Tooltip>
                <Tooltip title="Author">
                  <Box display="flex" alignItems="center">
                    <Person fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography variant="caption">
                      {post.authorName}
                    </Typography>
                  </Box>
                </Tooltip>
              </Box>
              <Tooltip title="Read more">
                <IconButton
                  component={Link}
                  to={`/blog/${post.slug}`}
                  size="small"
                  color="primary"
                >
                  <ArrowForward />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default BlogListCard;
