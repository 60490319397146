// ContactProfileCampaignMonitorHub.js
import React from 'react';
import { Card, CardContent, Tooltip, Typography } from '@mui/material';
import './ContactProfileCampaignMonitorHub.css';

const displayValueOrPlaceholder = (value, placeholder = 'N/A', isStatistic = false) => {
  if (isStatistic) {
    return value !== null ? value : '0'; // Treat null values as "0" for statistics
  }
  return value || placeholder;
};

const CampaignMonitorStatisticCard = ({ label, value }) => (
  <Card variant="outlined" className="email-statistic-card">
    <div className="campaign-monitor-logo-top-right">
      <Tooltip title="Campaign Monitor">
        <img src="campaign_monitor_logo.png" alt="Campaign Monitor Logo" className="campaign-monitor-logo-small" />
      </Tooltip>
    </div>
    <CardContent>
      <Typography variant="subtitle2" className="stat-label">{label}</Typography>
      <Tooltip title={`Total ${label.toLowerCase()}`}>
        <Typography variant="h6" className="stat-value">{displayValueOrPlaceholder(value, '0', true)}</Typography>
      </Tooltip>
    </CardContent>
  </Card>
);

const CampaignMonitorStatistics = ({ contact }) => {
  const emailStats = [
    { label: 'Delivered', value: contact.TotalDelivered },
    { label: 'Opens', value: contact.TotalOpens },
    { label: 'Clicks', value: contact.TotalClicks },
    { label: 'Bounces', value: contact.TotalBounces },
  ];

  return (
    <div className="email-stats-info-container">
      <div className="email-stats-grid">
        {emailStats.map((stat, index) => (
          <CampaignMonitorStatisticCard key={index} label={stat.label} value={stat.value} />
        ))}
      </div>
    </div>
  );
};

export default CampaignMonitorStatistics;
