// GoogleDataContext.js
import React, { createContext, useState, useContext } from 'react';

const GoogleDataContext = createContext(null);

export const useGoogleData = () => useContext(GoogleDataContext);

export const GoogleDataProvider = ({ children }) => {
  const [googleData, setGoogleData] = useState(null);
  const [googleStatistics, setGoogleStatistics] = useState(null);
  const [googleProcessFinished, setGoogleProcessFinished] = useState(false);
  const [googleProcessReady, setGoogleProcessReady] = useState(false); // New state
  const [isGoogleProcessing, setIsGoogleProcessing] = useState(false);
  const [googleSalesforceData, setGoogleSalesforceData] = useState([]); // New state
  const [selectedGoogleRows, setSelectedGoogleRows] = useState(new Set());
  const [googleNewWebsites, setGoogleNewWebsites] = useState([]); // New state
  const [googleServiceInfo, setGoogleServiceInfo] = useState(null); // New state
  const [googleNewCompanies, setGoogleNewCompanies] = useState([]); // New state

  return (
    <GoogleDataContext.Provider value={{ googleData, setGoogleData, googleProcessReady, setGoogleProcessReady, isGoogleProcessing, setIsGoogleProcessing, googleSalesforceData, setGoogleSalesforceData, selectedGoogleRows, setSelectedGoogleRows, googleStatistics, setGoogleStatistics, googleProcessFinished, setGoogleProcessFinished, googleNewWebsites, setGoogleNewWebsites, googleServiceInfo, setGoogleServiceInfo, googleNewCompanies, setGoogleNewCompanies }}>
      {children}
    </GoogleDataContext.Provider>
  );
};
