// src/components/Blog/BlogListPage.js
// src/components/Blog/BlogListPage.js
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Grid, Typography, Box, Fade } from '@mui/material';
import axios from 'axios';
import useAnalytics from '../../hooks/UseAnalytics';
import BlogListCard from './BlogListCard';

const BlogListPage = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView('blog_list');
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/fetch_all_blog_posts`);
        setPosts(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        setError('Failed to fetch blog posts. Please try again later.');
        setLoading(false);
      }
    };
    fetchPosts();
  }, [trackPageView]);

  if (error) return (
    <Typography color="error" align="center" variant="h6" sx={{ mt: 4 }}>
      {error}
    </Typography>
  );

  return (
    <Fade in={!loading}>
      <Container maxWidth="lg">
        <Helmet>
          <title>Blog - AgencyEdge</title>
          <meta name="description" content="Stay up to date with the latest in CRM and AI technology" />
        </Helmet>
        <Box my={4}>
          <Typography variant="h2" component="h1" gutterBottom align="center">
            AgencyEdge Blog
          </Typography>
          <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
            Insights and updates on CRM and AI technology
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {(loading ? Array.from(new Array(6)) : posts).map((post, index) => (
            <Grid item xs={12} sm={6} md={4} key={post ? post.id : index}>
              <BlogListCard post={post} loading={loading} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Fade>
  );
};

export default BlogListPage;
