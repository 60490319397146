//SalesforceLogin.js
import React, { useState } from 'react';
import { Button, Typography, Paper } from '@mui/material';
import './SalesforceLogin.css'; // Import the CSS file

function SalesforceLogin({ isLoading, setIsLoading }) {
  const [error, setError] = useState('');

  const handleSalesforceLogin = async () => {
    setIsLoading(true);
    const { codeChallenge, codeVerifier } = await generatePKCE();
    const clientId = process.env.REACT_APP_SALESFORCE_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_SALESFORCE_REDIRECT_URI;
    const loginUrl = `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${encodeURIComponent(clientId)}&redirect_uri=${encodeURIComponent(redirectUri)}&code_challenge=${encodeURIComponent(codeChallenge)}&code_challenge_method=S256`;

    console.log("Login URL:", loginUrl);
    console.log("Code verifier:", codeVerifier);

    localStorage.setItem('pkce_code_verifier', codeVerifier);
    window.location.href = loginUrl;
  };

  const generatePKCE = async () => {
    try {
        const codeVerifier = generateRandomString(120);
        const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier));
        const codeChallenge = base64urlencode(hashBuffer);
        return { codeChallenge, codeVerifier };
    } catch (error) {
        console.error("Error generating PKCE: ", error);
    }
  };          

  const generateRandomString = (length) => {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    const codeVerifier = Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
    if (codeVerifier.length > 128) {
        return codeVerifier.substr(0, 128); // Ensure the length is within the allowed range
    }
    return codeVerifier;
  };


  const base64urlencode = (arrayBuffer) => {
      return btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)))
          .replace(/\+/g, '-')
          .replace(/\//g, '_')
          .replace(/=+$/, '');
  };

  

    return (
        <Paper className="login-info" elevation={0}>
        <div className="login-info-header">
            <img src="salesforce_logo.png" alt="Salesforce Logo" className="service-logo" />
            <Typography variant="h6" className="service-title">Salesforce</Typography>
        </div>
        <div className="service-login-actions">
            <Button variant="contained" color="primary" onClick={handleSalesforceLogin}>
              Connect to Salesforce
            </Button>
        </div>
        </Paper>
    );
}

export default SalesforceLogin;
