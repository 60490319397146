
// src/hooks/UseAnalytics.js
import { useState, useEffect, useCallback } from 'react';
import { getClientInfo, getSource, getSessionInfo, getIPAddress, trackEvent as clientTrackEvent } from './ClientInfo';

const useAnalytics = () => {
  const [sessionId, setSessionId] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);

  useEffect(() => {
    const storedSessionId = localStorage.getItem('sessionId');
    if (storedSessionId) {
      setSessionId(storedSessionId);
    } else {
      const newSessionId = Date.now().toString(36) + Math.random().toString(36).substr(2);
      localStorage.setItem('sessionId', newSessionId);
      setSessionId(newSessionId);
    }

    // Fetch IP address
    getIPAddress().then(ip => setIpAddress(ip));
  }, []);

  const trackPageView = useCallback(async (pageName) => {
    if (!sessionId) return;

    const clientInfo = getClientInfo();
    const sourceInfo = getSource();
    const sessionInfo = getSessionInfo();

    const pageViewData = {
      sessionId,
      eventName: 'page_view',
      pageName,
      timestamp: new Date().toISOString(),
      clientInfo,
      sourceInfo,
      sessionInfo,
      ipAddress,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/track-event`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(pageViewData),
      });
      if (!response.ok) throw new Error('Failed to track page view');
    } catch (error) {
      console.error('Error tracking page view:', error);
      clientTrackEvent('page_view', pageViewData);
    }
  }, [sessionId, ipAddress]);

  const trackEvent = useCallback(async (eventName, eventData = {}) => {
    if (!sessionId) return;

    const clientInfo = getClientInfo();
    const sourceInfo = getSource();
    const sessionInfo = getSessionInfo();

    const fullEventData = {
      sessionId,
      eventName,
      timestamp: new Date().toISOString(),
      clientInfo,
      sourceInfo,
      sessionInfo,
      ipAddress,
      ...eventData,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/track-event`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(fullEventData),
      });
      if (!response.ok) throw new Error('Failed to track event');
    } catch (error) {
      console.error('Error tracking event:', error);
      clientTrackEvent(eventName, fullEventData);
    }
  }, [sessionId, ipAddress]);

  const logError = useCallback(async (errorMessage, errorDetails) => {
    if (!sessionId) return;

    const errorData = {
      sessionId,
      eventName: 'error',
      errorMessage,
      errorDetails,
      timestamp: new Date().toISOString(),
      ipAddress,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/track-event`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(errorData),
      });
      if (!response.ok) throw new Error('Failed to log error');
    } catch (error) {
      console.error('Error logging error:', error);
      clientTrackEvent('error', errorData);
    }
  }, [sessionId, ipAddress]);

  return { trackPageView, trackEvent, logError, sessionId };
};

export default useAnalytics;