// OpenAIDataContext.js
import React, { createContext, useState, useContext } from 'react';

const OpenAIDataContext = createContext(null);

export const useOpenAIData = () => useContext(OpenAIDataContext);

export const OpenAIDataProvider = ({ children }) => {
  const [openAIData, setOpenAIData] = useState(null);
  const [openAIStatistics, setOpenAIStatistics] = useState(null);
  const [openAIProcessFinished, setOpenAIProcessFinished] = useState(false);
  const [openAIServiceInfo, setOpenAIServiceInfo] = useState(null);
  const [openAISalesforceProcessReady, setOpenAISalesforceProcessReady] = useState(false); // New state
  const [openAIAnymailfinderProcessReady, setOpenAIAnymailfinderProcessReady] = useState(false); // New state
  const [isOpenAIProcessing, setIsOpenAIProcessing] = useState(false); // New state
  const [isProcessing, setIsProcessing] = useState(false);
  const [openAISalesforceData, setOpenAISalesforceData] = useState([]); // New state
  const [openAIAnymailfinderData, setOpenAIAnymailfinderData] = useState([]); // New state
  const [selectedOpenAIRows, setSelectedOpenAIRows] = useState(new Set());
  const [dataEnricherServiceInfo, setDataEnricherServiceInfo] = useState(null); // New state
  const [openAINewCompanies, setOpenAINewCompanies] = useState([]);
  const [openAIProcessReady, setOpenAIProcessReady] = useState(false); // New state

  console.log('OpenAIDataContext Provider Rendering');
  console.log({ openAIData, openAIProcessReady, isOpenAIProcessing, /* other states */ });


  return (
    <OpenAIDataContext.Provider value={{ openAIData, setOpenAIData, openAIServiceInfo, setOpenAIServiceInfo, openAISalesforceProcessReady, setOpenAISalesforceProcessReady, openAIAnymailfinderProcessReady, setOpenAIAnymailfinderProcessReady, isProcessing, setIsProcessing, openAISalesforceData, setOpenAISalesforceData, selectedOpenAIRows, setSelectedOpenAIRows, openAIStatistics, setOpenAIStatistics, openAIProcessFinished, setOpenAIProcessFinished, isOpenAIProcessing, setIsOpenAIProcessing, openAIAnymailfinderData, setOpenAIAnymailfinderData, dataEnricherServiceInfo, setDataEnricherServiceInfo, openAINewCompanies, setOpenAINewCompanies, openAIProcessReady, setOpenAIProcessReady }}>
      {children}
    </OpenAIDataContext.Provider>
  );
};
