//SalesforceConfig.js
import React from 'react';
import { Slider, Tooltip } from '@mui/material';
import { useSalesforceData } from './SalesforceDataContext';
import './UserConfigurations.css';

const BouncedRangeSlider = ({ value, onChange }) => {
    // Function to map numeric values to text labels
    const getSliderLabel = (value) => {
        const labels = {
            0: 'Bounced Only',
            1: 'All Contacts',
            2: 'Non-Bounced Only'
        };
        return labels[value] || value;
    };

    return (
        <div className="salesforce-config-slider">
            <Slider
                value={value}
                onChange={onChange}
                min={0}
                max={2}
                valueLabelDisplay="auto"
                step={1}
                aria-labelledby="bounced-range-slider"
            />
            <div className="slider-value-display">{getSliderLabel(value)}</div>
        </div>
    );
};



const RangeSlider = ({ value, onChange, min, max }) => (
    <div className="salesforce-config-slider">
      <Slider
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
      />
      <div className="slider-value-display">{value}</div>
    </div>
  );

const SalesforceConfig = () => {
  const { salesforceConfig, setSalesforceConfig } = useSalesforceData();

  // Translate slider value to filter settings
  const handleBouncedSliderChange = (event, newValue) => {
    const settings = [
      { includeBounced: true, includeNonBounced: false },
      { includeBounced: true, includeNonBounced: true },
      { includeBounced: false, includeNonBounced: true },
    ];
    setSalesforceConfig({
      ...salesforceConfig,
      filterByEmailBounced: settings[newValue],
    });
  };

  // Determine initial slider value based on current settings
  const sliderValue = salesforceConfig?.filterByEmailBounced?.includeBounced
    ? (salesforceConfig?.filterByEmailBounced?.includeNonBounced ? 'Bounced Only' : 'Non-Bounced Only')
    : 'All Contacts';


  const handleMaxRecordsChange = (event) => {
    setSalesforceConfig({
      ...salesforceConfig,
      maxRecords: parseInt(event.target.value, 10),
    });
  };

  return (
    <div className="salesforce-config-container">
      {salesforceConfig ? (
        <div className="salesforce-config-content">
          <div className="salesforce-config-card">
            <h6 className="salesforce-config-title">Contact Filters</h6>
            <BouncedRangeSlider
              value={sliderValue}
              onChange={handleBouncedSliderChange}
            />
          </div>
  
          <div className="salesforce-config-card">
            <h6 className="salesforce-config-title">Max Emails to Process</h6>
            <RangeSlider
            value={salesforceConfig.maxRecords}
            onChange={handleMaxRecordsChange}
            min={0}
            max={500}
            />
          </div>
        </div>
      ) : (
        <div className="loading">Loading Salesforce configuration...</div>
      )}
    </div>
  );  
};

export default SalesforceConfig;
