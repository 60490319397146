// src/components/Dashboard/EnrichedCompaniesList.js
import React, { useState, useMemo } from 'react';
import { useAppContext } from '../../context/AppContext';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, CircularProgress, TextField, Chip } from '@mui/material';
import EnrichedCompanyProfile from './EnrichedCompanies/EnrichedCompanyProfile';
import moment from 'moment';
import '../../styles/EnrichedCompaniesList.css';

const EnrichedCompaniesList = () => {
  const { enrichedCompanies, fetchMoreEnrichedCompanies } = useAppContext();
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const filteredCompanies = useMemo(() => {
    return enrichedCompanies.list.filter(company =>
      (company.CompanyName?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
      (company.CompanyType?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false)
    );
  }, [enrichedCompanies.list, searchTerm]);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    if ((newPage + 1) > enrichedCompanies.currentPage && newPage * rowsPerPage >= enrichedCompanies.list.length) {
      setLoading(true);
      await fetchMoreEnrichedCompanies(enrichedCompanies.currentPage + 1);
      setLoading(false);
    }
  };

  const handleCompanyClick = (company) => {
    setSelectedCompany(company);
  };

  const handleBackToList = () => {
    setSelectedCompany(null);
  };

  if (!enrichedCompanies.list.length) {
    return <CircularProgress />;
  }

  if (selectedCompany) {
    return <EnrichedCompanyProfile salesforceAccountId={selectedCompany.SalesforceAccountID} onBack={handleBackToList} />;
  }

  return (
    <Paper className="enriched-companies-list">
      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        label="Search enriched companies"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-field"
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow className="table-header">
              <TableCell>Company Name</TableCell>
              <TableCell>Company Type</TableCell>
              <TableCell>Headquarters</TableCell>
              <TableCell>Last Sync</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCompanies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((company) => (
              <TableRow 
                key={company.CompanyUUID}
                onClick={() => handleCompanyClick(company)}
                className="company-row"
              >
                <TableCell>{company.CompanyName || 'N/A'}</TableCell>
                <TableCell>{company.CompanyType || 'N/A'}</TableCell>
                <TableCell>{company.Headquarters || 'N/A'}</TableCell>
                <TableCell>
                  <Chip
                    label={company.LastModified ? moment(company.LastModified).format('MMM D, YYYY') : 'N/A'}
                    className="last-sync-chip"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={enrichedCompanies.totalCompanies}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25]}
      />
      {loading && <CircularProgress className="loading-indicator" />}
    </Paper>
  );
};

export default EnrichedCompaniesList;