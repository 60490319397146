// src/components/Dashboard/EnrichedCompanies/EnrichedCompanyProfile.js
import React, { useState, useEffect } from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EnrichedCompanySummary from './EnrichedCompanySummary';
import EnrichedCompanyMainDisplay from './EnrichedCompanyMainDisplay';
import { useAppContext } from '../../../context/AppContext';
import '../../../styles/EnrichedCompanies/EnrichedCompanyProfile.css';


const EnrichedCompanyProfile = ({ salesforceAccountId, onBack }) => {
  const [companyData, setCompanyData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { fetchEnrichedCompanyDetails } = useAppContext();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchEnrichedCompanyDetails(salesforceAccountId);
        setCompanyData(data);
      } catch (error) {
        console.error('Error fetching enriched company data:', error);
        setError('Failed to load company data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [salesforceAccountId, fetchEnrichedCompanyDetails]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="enriched-company-profile">
      <IconButton
        className="back-button"
        onClick={onBack}
        aria-label="back to enriched companies list"
      >
        <ArrowBackIcon />
      </IconButton>
      <EnrichedCompanySummary data={companyData.summary} targets={companyData.targets} />
      <EnrichedCompanyMainDisplay data={companyData} />
    </div>
  );
};

export default EnrichedCompanyProfile;