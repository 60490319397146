// salesforceServiceInfo.js
import React, { useState, useEffect } from 'react';
import { useSalesforceData } from './SalesforceDataContext';
import { useAnymailfinderData } from './AnymailfinderDataContext';
import { useOpenAIData } from './OpenAIDataContext';
import { Paper, Typography, IconButton, Tooltip, CircularProgress } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import './ServiceInfo.css';
import moment from 'moment';
import { Snackbar, Alert } from '@mui/material';
import { useCustomCompanyData } from './CustomCompanyDataContext';
import { useCodeBuddyData } from './CodeBuddyDataContext';





function SalesforceServiceInfo({ setIsConnected }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { salesforceData, setSalesforceData, setSalesforceContacts, setSalesforceAccounts, salesforceServiceInfo, setSalesforceServiceInfo, isSalesforceUpdating, setIsSalesforceUpdating, salesforceUpdateReady, setSalesforceUpdateReady, isSalesforceUpdated, setIsSalesforceUpdated, dataReceiverServiceInfo, setDataReceiverServiceInfo, salesforceConfig, salesforceCompanies, setSalesforceCompanies, isSalesforceProcessing, setIsSalesforceProcessing } = useSalesforceData();
    const { setAnymailfinderData, anymailfinderNewEmails, setAnymailfinderNewEmails, anymailfinderSalesforceData, setAnymailfinderSalesforceData } = useAnymailfinderData();
    const { codeBuddyData, codeBuddySelectedData, codeBuddyCompanySelectedData, isCodeBuddyProcessing, setIsCodeBuddyProcessing, syncBackToSalesforceProcessReady, codeBuddySalesforceProcessReady, setCodeBuddySalesforceProcessReady, setSyncBackToSalesforceProcessReady } = useCodeBuddyData();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // 'success', 'error', 'info', 'warning'
    const { handleLogout: contextHandleLogout } = useSalesforceData();
    const { openAISalesforceData, setOpenAISalesforceData } = useOpenAIData();

    
    const selectionCount = codeBuddySelectedData.length;


    useEffect (() => {
        if (codeBuddySalesforceProcessReady && codeBuddySelectedData && codeBuddySelectedData.length > 0) {
           // handleSyncNewEmails();
           //   handleSyncOpenAIData();
           handleSyncSalesforceWithCodeBuddy();
        }
    }, [codeBuddySalesforceProcessReady, codeBuddySelectedData]);

    useEffect (() => {
        if (syncBackToSalesforceProcessReady && codeBuddySelectedData && codeBuddySelectedData.length > 0) {
           // handleSyncNewEmails();
           //   handleSyncOpenAIData();
         //  handleSyncCodeBuddyCompanyBackToSalesforceOld();
           handleSyncCodeBuddyContactBackToSalesforce();
        }
    }, [syncBackToSalesforceProcessReady, codeBuddySelectedData]);


    useEffect (() => {
        if (syncBackToSalesforceProcessReady && codeBuddyCompanySelectedData && codeBuddyCompanySelectedData.length > 0) {
           // handleSyncNewEmails();
           //   handleSyncOpenAIData();
         //  handleSyncCodeBuddyCompanyBackToSalesforceOld();
           handleSyncCodeBuddyCompanyBackToSalesforce();
        }
    }, [syncBackToSalesforceProcessReady, codeBuddyCompanySelectedData]);







    const handleSyncNewEmails = async () => {
        setIsSalesforceUpdating(true);
        setLoading(true);
        setError('');
    
        try {
            const accessToken = localStorage.getItem('salesforce_access_token');
            const instanceUrl = localStorage.getItem('salesforce_instance_url');
            const newEmails = anymailfinderSalesforceData;
    
            if (!accessToken || !instanceUrl || !anymailfinderSalesforceData) {
                throw new Error('Missing required data for syncing.');
            }
    
            setSnackbarMessage("Syncing new Anymailfinder emails...");
            setSnackbarSeverity("info");
            setSnackbarOpen(true);
    
            await updateContacts(accessToken, instanceUrl, newEmails);
            await fetchDataReceiverServiceInfo();
            await fetchStoredSalesforceData();
            setSnackbarMessage("New emails synced successfully.");
            setSnackbarSeverity("success");
        } catch (err) {
            setSnackbarMessage(`${err.message}. Try Disconnecting and Reconnecting from Salesforce.`);
            setSnackbarSeverity("error");
            setIsSalesforceUpdating(false);
            setSalesforceUpdateReady(false);
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
            setIsSalesforceUpdating(false);
            setSalesforceUpdateReady(false);
            setIsSalesforceUpdated(true);
        }
    };

    const handleSyncOpenAIData = async () => {
        setIsSalesforceUpdating(true);
        setLoading(true);
        setError('');

        try {
            const accessToken = localStorage.getItem('salesforce_access_token');
            const instanceUrl = localStorage.getItem('salesforce_instance_url');
            const openAISalesforceData = openAISalesforceData;

            if (!accessToken || !instanceUrl || !openAISalesforceData) {
                throw new Error('Missing required data for syncing.');
            }

            setSnackbarMessage("Syncing OpenAI data...");
            setSnackbarSeverity("info");
            setSnackbarOpen(true);

            await syncCodeBuddyWithSalesforce(accessToken, instanceUrl, openAISalesforceData);
            await fetchDataReceiverServiceInfo();
            await fetchStoredSalesforceData();
            setSnackbarMessage("OpenAI data synced successfully.");
            setSnackbarSeverity("success");
        } catch (err) {
            setSnackbarMessage(`${err.message}. Try Disconnecting and Reconnecting from Salesforce.`);
            setSnackbarSeverity("error");
            setIsSalesforceUpdating(false);
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
            setIsSalesforceUpdating(false);
            setIsSalesforceUpdated(true);
        }
    };


    const calculateBouncedPercentage = () => {
        const totalContacts = salesforceServiceInfo?.TotalContacts || 0;
        const totalBouncedContacts = salesforceServiceInfo?.TotalBouncedContacts || 0;
        return totalContacts > 0 ? ((totalBouncedContacts / totalContacts) * 100).toFixed(1) : 0;
    };

    const calculateUpdateContactsPercentage = () => {
        const totalBouncedContacts = salesforceServiceInfo?.TotalBouncedContacts || 0;
        const totalUpdatedContacts = salesforceServiceInfo?.TotalUpdatedContacts || 0;
        return totalBouncedContacts > 0 ? ((totalUpdatedContacts / totalBouncedContacts) * 100).toFixed(1) : 0;
    };


    const syncCodeBuddyWithSalesforce = async (accessToken, instanceUrl, openAISalesforceData) => {
        const userId = localStorage.getItem('userId'); // Get UserId from localStorage
        if (!userId) {
            throw new Error('User ID is not available');
        }

        const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/sync_codebuddy_with_salesforce`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'InstanceUrl': instanceUrl,
                'UserId': userId, // Add UserId to the request headers
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(openAISalesforceData)
        });

        if (!response.ok) {
            throw new Error('Unknown error. Try re-loading the page.');
        }

        return await response.json();
    };


    const syncCodeBuddyBackToSalesforce = async (accessToken, instanceUrl, newCodeBuddyData) => {
        const userId = localStorage.getItem('userId'); // Get UserId from localStorage
        if (!userId) {
            throw new Error('User ID is not available');
        }
    
        const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/sync_contacts_back_to_salesforce`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'InstanceUrl': instanceUrl,
                'UserId': userId, // Add UserId to the request headers
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newCodeBuddyData)
        });
    
        if (!response.ok) {
            throw new Error('Unknown error. Try re-loading the page.');
        }
    
        return await response.json();
    };

    const updatePendingSalesforceContact = async (accessToken, instanceUrl) => {
        const userId = localStorage.getItem('userId'); // Get UserId from localStorage
        if (!userId) {
            throw new Error('User ID is not available');
        }

        const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/update_pending_salesforce_contacts`, { // Add /api prefix to match backend route
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'InstanceUrl': instanceUrl,
                'UserId': userId, // Add UserId to the request headers
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({}) // Ensure body is passed even if empty

        });

        if (!response.ok) {
            throw new Error('Unknown error. Try re-loading the page.');
        }

        return await response.json();
    };


    

    const updatePendingSalesforceCompany = async (accessToken, instanceUrl) => {
        const userId = localStorage.getItem('userId'); // Get UserId from localStorage
        if (!userId) {
            throw new Error('User ID is not available');
        }
    
        const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/update_pending_salesforce_company`, { // Add /api prefix to match backend route
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'InstanceUrl': instanceUrl,
                'UserId': userId, // Add UserId to the request headers
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({}) // Ensure body is passed even if empty
        });
    
        if (!response.ok) {
            throw new Error('Unknown error. Try re-loading the page.');
        }
    
        return await response.json();
    };
    

    



    const updateContacts = async (accessToken, instanceUrl, newEmails) => {
        const userId = localStorage.getItem('userId'); // Get UserId from localStorage
        if (!userId) {
            throw new Error('User ID is not available');
        }
    
        const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/update_salesforce_contacts`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'InstanceUrl': instanceUrl,
                'UserId': userId, // Add UserId to the request headers
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newEmails)
        });
    
        if (!response.ok) {
            throw new Error('Unknown error. Try re-loading the page.');
        }
    
        return await response.json();
    };
    

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const timeDiff = now - date; // Difference in milliseconds

        // Determine the color based on time difference
        let colorClass = 'sync-time-recent';
        if (timeDiff > 24 * 60 * 60 * 1000) { // Older than 24 hours
            colorClass = 'sync-time-old';
        } else if (timeDiff > 60 * 60 * 1000) { // Older than 1 hour
            colorClass = 'sync-time-warning';
        }

        return { 
          formattedDate: moment(date).fromNow(), 
          fullDate: moment(date).format('LLLL'), // full date format
          colorClass 
        };
    };

    const handleSyncCodeBuddyContactBackToSalesforce = async () => {
        setIsCodeBuddyProcessing(true);
        setLoading(true);
        setIsSalesforceProcessing(true);
        setError('');

        try {
            const accessToken = localStorage.getItem('salesforce_access_token');
            const instanceUrl = localStorage.getItem('salesforce_instance_url');
            if (!accessToken || !instanceUrl) {
                throw new Error('Please authenticate with Salesforce.');
            }

            setSnackbarMessage("Syncing CodeBuddy data back to Salesforce...");
            setSnackbarSeverity("info");
            setSnackbarOpen(true);

            await updatePendingSalesforceContact(accessToken, instanceUrl);

            setSnackbarMessage("CodeBuddy data synced successfully.");
            setSnackbarSeverity("success");
        } catch (err) {
            setSnackbarMessage(`${err.message}. Try re-loading the page.`);
            setSnackbarSeverity("error");
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
            setIsCodeBuddyProcessing(false);
            setIsSalesforceProcessing(false);
            setCodeBuddySalesforceProcessReady(false);
            setSyncBackToSalesforceProcessReady(false);
        }
    };




    const handleSyncCodeBuddyCompanyBackToSalesforce = async () => {
        setIsCodeBuddyProcessing(true);
        setLoading(true);
        setIsSalesforceProcessing(true);
        setError('');

        try {
            const accessToken = localStorage.getItem('salesforce_access_token');
            const instanceUrl = localStorage.getItem('salesforce_instance_url');

            if (!accessToken || !instanceUrl) {
                throw new Error('Please authenticate with Salesforce.');
            }

            setSnackbarMessage("Syncing CodeBuddy data back to Salesforce...");
            setSnackbarSeverity("info");
            setSnackbarOpen(true);

            await updatePendingSalesforceCompany(accessToken, instanceUrl);

            setSnackbarMessage("CodeBuddy data synced successfully.");
            setSnackbarSeverity("success");

        } catch (err) {
            setSnackbarMessage(`${err.message}. Try re-loading the page.`);
            setSnackbarSeverity("error");
        } finally {

            setLoading(false);
            setSnackbarOpen(true);
            setIsCodeBuddyProcessing(false);
            setIsSalesforceProcessing(false);
            setCodeBuddySalesforceProcessReady(false);
            setSyncBackToSalesforceProcessReady(false);
        }
    };



    const handleSyncCodeBuddyCompanyBackToSalesforceOld = async () => {
        setIsCodeBuddyProcessing(true);
        setLoading(true);
        setIsSalesforceProcessing(true);
        setError('');

        try {
            const accessToken = localStorage.getItem('salesforce_access_token');
            const instanceUrl = localStorage.getItem('salesforce_instance_url');
            const newCodeBuddyData = codeBuddySelectedData;
            if (!accessToken || !instanceUrl || !newCodeBuddyData) {
                throw new Error('Missing required data for syncing.');
            }


            setSnackbarMessage("Syncing CodeBuddy data back to Salesforce...");
            setSnackbarSeverity("info");
            setSnackbarOpen(true);

            await syncCodeBuddyBackToSalesforce(accessToken, instanceUrl, newCodeBuddyData);

       //     await fetchDataSourceServiceInfo();
       //     await fetchStoredSalesforceData();

            await fetchDataReceiverServiceInfo();

            setSnackbarMessage("CodeBuddy data synced successfully.");
            setSnackbarSeverity("success");
        } catch (err) {
            setSnackbarMessage(`${err.message}. Try re-loading the page.`);
            setSnackbarSeverity("error");
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
            setIsCodeBuddyProcessing(false);
            setIsSalesforceProcessing(false);
            setCodeBuddySalesforceProcessReady(false);
            setSyncBackToSalesforceProcessReady(false);
        }
    };
            



    const handleSyncSalesforceWithCodeBuddy = async () => {

        setIsSalesforceProcessing(true);


        try {
            const accessToken = localStorage.getItem('salesforce_access_token');
            const instanceUrl = localStorage.getItem('salesforce_instance_url');
            if (!accessToken || !instanceUrl) {
                throw new Error('Please authenticate with Salesforce.');
            }


            setSnackbarMessage("Syncing Salesforce Data. This may take up to 60 seconds...");
            setSnackbarSeverity("info");
            setSnackbarOpen(true)

            await fetchSalesforceData();



            setSnackbarMessage("Validating data. Hang tight we're almost done...");
            setSnackbarSeverity("info");
            setSnackbarOpen(true)


            await fetchDataSourceServiceInfo();
            setSnackbarMessage("Salesforce data synced successfully at " + formatDate(salesforceServiceInfo.AttemptTimeStart).fullDate);
            setSnackbarSeverity("success");
        } catch (err) {
            setSnackbarMessage(`Synchronization failed: ${err.message}. Try re-loading the page.`);
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
            setIsSalesforceProcessing(false);
            setIsCodeBuddyProcessing(false);
            setCodeBuddySalesforceProcessReady(false);

        }
    };

    const fetchStoredSalesforceData = async () => {
        setError('');
      
        try {
          const userId = localStorage.getItem('userId'); // Get userId from localStorage
          if (!userId) {
            throw new Error('User ID is not available');
          }
      
          const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_stored_salesforce_data`, {
            headers: {
              'UserId': userId // Pass userId in the request header
            }
          });
      
          if (!response.ok) {
            throw new Error('Failed to fetch stored Salesforce service data');
          }
      
          const data = await response.json();
          fetchDataSourceServiceInfo();
          fetchDataReceiverServiceInfo();
      
          console.log("Salesforce data, after filtering:", data);
        } catch (err) {
          setError(err.message);
        } finally {
          console.log("Salesforce data source service info:", salesforceServiceInfo);
        }
    };

    

    const fetchDataReceiverServiceInfo = async () => {
        try {
            const userId = localStorage.getItem('userId'); // Get UserId from localStorage
            if (!userId) {
                throw new Error('User ID is not available');
            }
    
            const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_data_receiver_service_info`, {
                headers: {
                    'UserId': userId, // Add UserId to the request headers
                }
            });
            
            if (!response.ok) {
                throw new Error('Failed to fetch stored Data Receiver service data');
            }
    
            const data = await response.json();
            setDataReceiverServiceInfo(data);
            console.log("Data Receiver service info:", data);
        } catch (err) {
            console.error('Error:', err.message);
        }
    };
    


    const fetchDataSourceServiceInfo = async () => {
        try {
            const userId = localStorage.getItem('userId'); // Get UserId from localStorage
            if (!userId) {
                throw new Error('User ID is not available');
            }
    
            const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_salesforce_service_info`, {
                headers: {
                    'UserId': userId, // Add UserId to the request headers
                }
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch stored Salesforce service data');
            }
    
            const data = await response.json();
            setSalesforceServiceInfo(data);
            console.log("Data source service info:", data);
        } catch (err) {
            console.error('Error:', err.message);
        }
    };
    








    const fetchSalesforceData = async () => {
        const userId = localStorage.getItem('userId'); // Get UserId from localStorage
        if (!userId) {
            throw new Error('User ID is not available');
        }
    
        const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/salesforce/get_salesforce_contacts`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('salesforce_access_token')}`,
                'InstanceUrl': localStorage.getItem('salesforce_instance_url'),
                'UserId': userId, // Add UserId to the request headers
            }
        });
    
        if (!response.ok) {
            throw new Error('Failed to fetch combined Salesforce data');
        }
    
        let data = await response.json();
        setSalesforceData(data);
        console.log("Salesforce data, after filtering:", data);
    };
    



    const getSyncDetailsTooltip = () => {
        if (codeBuddySalesforceProcessReady) {
            return "Salesforce sync in-progress...";
        } else {
            return "See contacts";
        }
    };



    const getIconBasedOnState = () => {
        if (codeBuddySalesforceProcessReady) {
            return <CircularProgress size={24} />;
        } else {
            // Dynamically render the selected count in a modern, visually appealing way
            return (
                <div className="selected-count">
                    {codeBuddySelectedData.length}
                    <Typography variant="caption" display="block">
                        selected
                    </Typography>
                </div>
            );
        }
    };

    const getSyncDetailsMessage = () => {
        if (codeBuddySalesforceProcessReady) {
            return `Syncing with CodeBuddy...`;
        }
    };


      return (
        <div className="service-info">

            {salesforceData && (
                <div className="loading-container">
                    <Tooltip title={getSyncDetailsTooltip()}>
                        <div className="sync-details">
                            {getIconBasedOnState()}
                            <Typography variant="body2" className="loading-message">
                            {getSyncDetailsMessage()}
                            </Typography>
                        </div>
                    </Tooltip>
                </div>
            )}

        </div>
    );
}

export default SalesforceServiceInfo;