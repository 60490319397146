//PricingHeader.js
import React from 'react';
import { Typography, Box, Tooltip, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import InfoIcon from '@mui/icons-material/Info';

const PricingHeader = ({ children }) => {
  return (
    <Box className="pricing-header" mt={4} mb={0}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box className="header-text" textAlign={{ xs: 'center', md: 'left' }}>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Typography variant="h1" className="main-title" gutterBottom>
                What is your CRM size?
              </Typography>
              <Typography variant="h2" className="subtitle" gutterBottom>
                Custom pricing based on your needs
              </Typography>
              <Typography variant="body1" className="description">
                Select the plan that fits your needs and scale as you grow
                <Tooltip title="CRM size refers to the number of contacts in your database" arrow>
                  <InfoIcon fontSize="small" style={{ marginLeft: '8px', verticalAlign: 'middle' }} />
                </Tooltip>
              </Typography>
            </motion.div>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="header-slider" textAlign="center">
            {children}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PricingHeader;