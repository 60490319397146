//ActiveSubscribersTile.js
import React from 'react';
import SummaryTile from './SummaryTile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import '../../../styles/tiles/ActiveSubscribersTile.css';

const ActiveSubscribersTile = ({ activeSubscribers, newSubscribers, subscriberPercentage, onClick }) => {
  const subContent = `${subscriberPercentage}% active | ${newSubscribers} new`;
  return (
    <SummaryTile
      title="Active Subscribers"
      mainMetric={activeSubscribers}
      subContent={subContent}
      icon={<CheckCircleIcon className="summary-tile-icon" />}
      onClick={onClick}
    />
  );
};

export default ActiveSubscribersTile;


