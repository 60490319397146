// UserConfigurations.js
import React, { useEffect, useState } from 'react';
import SalesforceConfig from './SalesforceConfig';
import { Button, Snackbar, Alert } from '@mui/material';
import { useSalesforceData } from './SalesforceDataContext';
import './UserConfigurations.css';
import CircularProgress from '@mui/material/CircularProgress';
import { useAnymailfinderData } from './AnymailfinderDataContext';

const UserConfigurations = () => {
  const { salesforceConfig, setSalesforceData, salesforceData, setDataSourceServiceInfo, dataSourceServiceInfo } = useSalesforceData();
  const { setCustomCompanyAnymailfinderData } = useAnymailfinderData();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');


    const triggerSaveAndFetch = async () => {
        setIsLoading(true);
        try {
          await saveConfiguration();
          await applyConfiguration();
        } catch (error) {
          console.error('Error during save and fetch operations:', error);
          setSnackbarMessage('An error occurred during the operation');
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
        } finally {
          setIsLoading(false);
        }
    };
      

  const saveConfiguration = async () => {
    const projectId = '1070';
    const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/configurations/put-salesforce-config/${projectId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(salesforceConfig)
    });

    const data = await response.json();
    console.log('Response from saveConfiguration:', data);
    setSnackbarMessage('Configuration saved successfully');
    setSnackbarSeverity('success');
    setOpenSnackbar(true);
    };

    // Take the salesforceData and filter it based on the salesforceConfig to setCustomeCompanyAnymailfinderData
    const applyConfiguration = async () => {
        if (salesforceData) {
            if (salesforceConfig.filterByEmailBounced) {
              setCustomCompanyAnymailfinderData(salesforceData.filter(item => {
                return salesforceConfig.filterByEmailBounced.includeBounced && item.State === 'Bounced' ||
                        salesforceConfig.filterByEmailBounced.includeNonBounced && item.State !== 'Bounced';
              }));
            }
            if (salesforceConfig.maxRecords) {
              setCustomCompanyAnymailfinderData(salesforceData.slice(0, salesforceConfig.maxRecords));
            }
          }
    }

    return (
        <div className="configurations-component">
            <SalesforceConfig />
            <Button 
                variant="contained" 
                color="primary" 
                onClick={triggerSaveAndFetch} 
                disabled={isLoading}
                className="save-button"
            >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Save'}
            </Button>
            <Snackbar open={openSnackbar} autoHideDuration={8000} onClose={() => setOpenSnackbar(false)}>
                <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default UserConfigurations;

